import { createDevNotice } from 'utils'
import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { getModuleDataInterface, getModuleOptionsInterface } from 'modules/Schedule/models'
import {
  getPageConvertedData,

  getRenderLpuData,
  getRenderDoctorsData,
  getRenderServicesData,
  getRenderDoctorsLpuData,
} from 'modules/Schedule/classes/ScheduleData/functions'

/**
 * @description
 * Модуль производит работу с данными.
 * Он собирает, обновляет и удаляет данные.
 * Его задача - хранилище данных.
 *
 * @param { Object } opts
 * @param { Object } opts.options - настройки модуля 'Schedule' - интерфейс 'getModuleOptionsInterface'
 * */
class ScheduleData {
  data = getModuleDataInterface()

  #options = getModuleOptionsInterface()

  constructor({ options }) {
    this.#options = options
    this.create()
  }

  /**
     * @public
     * @description
     * Собирает данные со страницы, форматирует и типизирует их
     * */
  create() {
    try {
      this.data = getPageConvertedData({ options: this.#options })
    } catch (error) { this._createNotice(error) }
  }

  /**
     * @public
     * @description
     * Сбрасывает данные, используя базовый интерфейс за основу.
     * */
  reset() { this.data = getModuleDataInterface() }

  /**
     * @public
     * @description
     * Производит обновление данных для рендера расписания.
     *
     * @param { Object } response
     * @param { Array } response.result - результат ответа
     * @param { Array } response.data - данные ответа по телемеду
     * @param { Boolean } [isPreliminaryConsultation] - требует ли процедура первичный приём. Используется только на услугах
     * @param { Boolean } [isTelemed] - по телемеду ли запись
     * @param { HTMLElement | undefined } parentCard - родительский узел, в котором произошло событие.
     * Если необходимо собрать данные для рендера для всей страницы - parentCard === undefined
     * Если необходимо отрендерить календарь только в 1 карточке - должен быть передан parentCard === HTMLElement
     * */
  updateRenderData(response, parentCard, isPreliminaryConsultation, isTelemed) {
    try {
      const responseData = isTelemed ? response.data : response.result

      switch (this.#options.pageType) {
        case PAGE_TYPE_DATA.lpu:
          this.data.render = getRenderLpuData({
            response: responseData,
            parentCard,
            data: this.data,
            dataItem: this.#options.dataItem,
          })

          break
        case PAGE_TYPE_DATA.doctors:
          this.data.render = getRenderDoctorsData({
            response: responseData,
            parentCard,
            data: this.data,
            dataItem: this.#options.dataItem,
            isTelemed,
          })

          break
        case PAGE_TYPE_DATA.doctorsLpu:
          this.data.render = getRenderDoctorsLpuData({
            response: responseData,
            parentCard,
            data: this.data,
            dataItem: this.#options.dataItem,
            isTelemed,
          })

          break
        case PAGE_TYPE_DATA.services:
          this.data.render = getRenderServicesData({
            response: responseData,
            isPreliminaryConsultation,
            parentCard,
            data: this.data,
            dataItem: this.#options.dataItem,
          })

          break
      }
    } catch (error) { this._createNotice(error) }
  }

  /**
     * @public
     * @description
     * Производит полное обновление данных для запроса.
     *
     * @param { Object } opts
     * @param { Object } opts.requestData - один из интерфейсов 'getLpuRequestInterface' | 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface' | 'getServicesRequestInterface'
     * */
  updateRequestData({ requestData }) {
    try {
      this.data.request = requestData
    } catch (error) { this._createNotice(error) }
  }

  /**
     * @public
     * @description
     * Производит обновление Map списка со всеми отрендеренными инстансами расписания.
     * Причем не полностью заменяя, а подменяя часть содержимого Map списка на список инстансов, который передан.
     *
     * @param { Object } opts
     * @param { Map } opts.instances - список инстансов календарей, который необходимо добавить.
     * Форма списка - { HTMLElement: VueComponent }(его возвращает 'ScheduleCalendar.render()')
     * */
  updateInstanceData({ instances }) {
    try {
      instances.forEach((instance, node) => { this.data.instances.set(node, instance) })
    } catch (error) { this._createNotice(error) }
  }

  /**
     * @private
     * @description
     * Логирует ошибку и отправляет ее в 'Sentry'
     *
     * @param { Object } - объект ошибки
     * */
  _createNotice({ message }) {
    createDevNotice({
      module: this.constructor.name,
      description: message,
    })
  }
}

export default ScheduleData

import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { createDevNotice, getTimezoneOffsetInHours, isNetworkDisconnect } from 'utils'
import type { Slots } from 'www/api/types'
import { type RedirectValue } from 'www/constants'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import { requestSlots } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/api'
import { ScheduleType } from 'www/api/apiScheduleTelemed.types'
import { apiScheduleDoctors, apiScheduleServices, apiScheduleTelemed } from 'www/api'

export default (lpuId: number) => defineStore(`doctorAppointmentCard/${lpuId}`, () => {
  const doctorPageLpuListStore = useDoctorPageLpuListStore()
  const { filterType } = storeToRefs(doctorPageLpuListStore)
  const externalDataStore = useExternalDataStore()
  const { currentDate, townTimedelta } = storeToRefs(externalDataStore)

  const lpuId = ref(0)
  const timedelta = ref(getTimezoneOffsetInHours())
  const hasSlots = ref(false)
  const nowDateString = ref(format(new Date(), 'yyyy-MM-dd', { locale: ru }))
  const selectedItem = ref<{
    id: number | null
    synonymId: number | null
    doctorId: number | null
    name: string | null
    price: string | null
    clubPrice: string | null
    agesText: string | null
    isWorkplace: boolean | null
    priceList: {
      price: number | null
      priceHome: number | null
      priceOnline: number | null
      priceMedtochkaTelemed: number | null
      priceClub: number | null
    } | null,
  }>({
    id: null,
    synonymId: null,
    doctorId: null,
    name: '',
    price: null,
    clubPrice: null,
    agesText: '',
    isWorkplace: false,
    priceList: {
      price: null,
      priceHome: null,
      priceOnline: null,
      priceMedtochkaTelemed: null,
      priceClub: null,
    },
  })
  const calendarSlots = ref<Slots>({})
  const fullCalendarSlots = ref({})
  const isRequestSlotsDone = ref(false)
  const isVisibleFullCalendar = ref(false)
  const isRequestedFullCalendar = ref(false)
  const redirectValue = ref<RedirectValue>('default')

  const translatedChosenFilter = computed(() => ({
    clinic: 'price',
    home: 'priceHome',
    telemed: 'priceOnline',
  }[filterType.value]))

  const isTelemedPricesDifferent = computed(() => !!selectedItem.value.priceList
    && typeof selectedItem.value.priceList.priceOnline === 'number'
    && typeof selectedItem.value.priceList.priceMedtochkaTelemed === 'number'
    && selectedItem.value.priceList.priceOnline !== selectedItem.value.priceList.priceMedtochkaTelemed)

  const scheduleType = computed<ScheduleType>(() => {
    if (!isTelemedPricesDifferent.value) {
      return 'all'
    }

    return redirectValue.value === 'telemed_mt' ? 'mt' : 'lpu'
  })

  function resetSlots() {
    isRequestedFullCalendar.value = false
    calendarSlots.value = {}
    fullCalendarSlots.value = {}
  }

  async function requestCalendarSlots({
    days = 14,
    allSlots = false,
    isFullCalendar = false,
  }: {
    days?: 14 | 30
    allSlots?: boolean
    isFullCalendar?: boolean
  } = {}) {
    if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
      try {
        let slots: Slots | null = null

        isRequestSlotsDone.value = false

        if (filterType.value === 'telemed') {
          const response = await apiScheduleTelemed.post({
            days,
            user_start_date: currentDate.value,
            only_free: !isFullCalendar,
            user_timezone: getTimezoneOffsetInHours(),
            lpu_params: [{
              lpu_id: lpuId.value,
              schedule_params: [{
                doctor_id: selectedItem.value.doctorId ?? 0,
                type_schedule: scheduleType.value,
              }],
            }],
          })

          slots = apiScheduleTelemed.transformSlots(response.data[0].schedule_data[0].schedule)
        } else if (filterType.value === 'clinic') {
          if (selectedItem.value.isWorkplace) {
            const response = await apiScheduleDoctors.post({
              days,
              dt_start: currentDate.value,
              all_slots: isFullCalendar,
              town_timedelta: townTimedelta.value ?? 0,
              doctors_lpus: [{
                lpu_id: lpuId.value,
                doctor_id: selectedItem.value.doctorId ?? 0,
                lpu_timedelta: timedelta.value,
                has_slots: hasSlots.value,
              }],
            })

            slots = response.data.result[0]?.slots
          } else {
            const response = await apiScheduleServices.post({
              days,
              day_start: currentDate.value,
              only_free: !isFullCalendar,
              town_timedelta: townTimedelta.value ?? 0,
              services: [{
                lpu_id: lpuId.value,
                service_id: selectedItem.value.synonymId ?? 0,
                doctor_id: selectedItem.value.doctorId ?? 0,
              }],
            })

            slots = response.data.result[0]?.slots
          }
        }

        if (slots) {
          if (isFullCalendar) {
            fullCalendarSlots.value = slots
          } else {
            calendarSlots.value = slots
          }
        }
      } catch (error) {
      // @ts-ignore
        const { message, response } = {}
        const { status } = response || {}

        if (isNetworkDisconnect({
          status,
          message,
        })) {
          return
        }

        createDevNotice({
          description: message || error,
          method: 'requestCalendarSlots',
          module: 'useDoctorAppointmentCardStore',
        })
      } finally {
        isRequestSlotsDone.value = true
      }
    } else {
      const slotsData = {
        days,
        allSlots,
        nowDateString: nowDateString.value,
        timedelta: timedelta.value,
      }

      if (selectedItem.value.isWorkplace) {
        Object.assign(slotsData, {
          doctorsAndLpus: [{
            lpuId: lpuId.value,
            doctorId: selectedItem.value.doctorId,
            timedelta: timedelta.value,
            hasSlots: hasSlots.value,
          }],
        })
      } else {
        Object.assign(slotsData, {
          services: [{
            lpuId: lpuId.value,
            serviceId: selectedItem.value.synonymId,
            doctorId: selectedItem.value.doctorId,
          }],
        })
      }

      isRequestSlotsDone.value = false

      try {
        const slots = await requestSlots({
          slotsData,
          isWorkplace: selectedItem.value.isWorkplace ?? false,
          isTelemed: filterType.value === 'telemed',
        })

        if (isFullCalendar) {
          fullCalendarSlots.value = slots
        } else {
          calendarSlots.value = slots
        }
      } catch (error) {
      // @ts-ignore
        const { message, response } = {}
        const { status } = response || {}

        if (isNetworkDisconnect({
          status,
          message,
        })) {
          return
        }

        createDevNotice({
          description: message || error,
          method: 'requestCalendarSlots',
          module: 'useDoctorAppointmentCardStore',
        })
      } finally {
        isRequestSlotsDone.value = true
      }
    }
  }

  return {
    lpuId,
    timedelta,
    hasSlots,
    nowDateString,
    selectedItem,
    isTelemedPricesDifferent,
    scheduleType,
    calendarSlots,
    fullCalendarSlots,
    isRequestSlotsDone,
    isVisibleFullCalendar,
    isRequestedFullCalendar,
    translatedChosenFilter,
    redirectValue,
    resetSlots,
    requestCalendarSlots,
  }
})

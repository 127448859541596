import { defineStore } from 'pinia'
import {
  isEmptyObject,
  setQueryParam,
  deleteQueryParam,
  getFormattedPrice,
} from 'utils'
import {
  getDoctorData,
  getDoctorsData,
  getSelectedDoctor,
} from 'components/common/AppointmentPage/interfaces'
import { getDoctorDescription, getHasSuitableAgeInWorkplace } from 'components/common/AppointmentPage/functions'
import { APPOINTMENT_TYPES } from 'constants'
// eslint-disable-next-line import/no-cycle
import useClubDataStore from 'components/common/AppointmentPage/stores/useClubDataStore'
// eslint-disable-next-line import/no-cycle
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'
// eslint-disable-next-line import/no-cycle
import useFamilyProfilesStore from 'components/common/AppointmentPage/stores/useFamilyProfilesStore'

const useDoctorDataStore = defineStore('doctorDataStore', {
  state: () => ({
    selectedDoctorSpecialityId: null,
    doctorData: getDoctorData(),
    doctorsData: getDoctorsData(),
    selectedDoctor: getSelectedDoctor(),
    appointmentSpecialitiesRef: null,
    isErrorStateSpeciality: false,
  }),
  getters: {
    selectedWorkplace() {
      const { appointmentObjectTypes } = useAppointmentDataStore()
      const workplace = this.doctorData.workplaces.find(
        ({ speciality }) => speciality.id === this.selectedDoctorSpecialityId,
      ) || {}

      if (appointmentObjectTypes.isServices) {
        return workplace
      }

      workplace.hasSuitableAge = true

      const { selectedPatient } = useFamilyProfilesStore()
      const { ageNumber: ageNumberPatient } = selectedPatient

      workplace.hasSuitableAge = getHasSuitableAgeInWorkplace({
        workplace,
        ageNumberPatient,
      })

      return workplace
    },
    isTelemedPricesDifferent() {
      if (!this.selectedWorkplace?.priceList) {
        return false
      }

      const { priceOnline, priceMedtochkaTelemed } = this.selectedWorkplace.priceList

      return !!priceOnline && !!priceMedtochkaTelemed && priceOnline !== priceMedtochkaTelemed
    },
    rangeAgeLimit() {
      const { appointmentObjectTypes } = useAppointmentDataStore()

      if (appointmentObjectTypes.isServices && this.selectedDoctor?.workplace?.age) {
        return this.selectedDoctor.workplace.age
      }

      if (this.selectedDoctorSpecialityId && this.doctorData.workplaces.length) {
        return this.selectedWorkplace.age
      }

      return {}
    },
    rangeAgeLimitAllSpecialities() {
      const { appointmentObjectTypes } = useAppointmentDataStore()

      if (appointmentObjectTypes.isDoctor) {
        return this.doctorData.workplaces.map(({ age }) => age)
      }

      if (appointmentObjectTypes.isServices) {
        return [this.selectedDoctor.workplace?.age]
      }
    },
    getFullPriceForWorkplace: () => workplace => {
      if (!workplace.priceList) {
        return ''
      }

      const appointmentDataStore = useAppointmentDataStore()

      let fullPrice
      const {
        price, priceHome, priceOnline, priceMedtochkaTelemed,
      } = workplace.priceList
      const {
        isLpu, isHome, isOnline, isTelemed, isClub, isTelemedMedtochka,
      } = appointmentDataStore.appointmentPlaceTypes

      if (isLpu || isClub) {
        fullPrice = price
      } else if (isHome) {
        fullPrice = priceHome
      } else if (isOnline || isTelemed) {
        fullPrice = priceOnline
      } else if (isTelemedMedtochka) {
        fullPrice = priceMedtochkaTelemed
      }

      return getFormattedPrice(fullPrice)
    },
    getClubPriceForWorkplace: () => ({ priceList }) => (
      priceList
        ? getFormattedPrice(priceList.priceClub)
        : ''
    ),
    fullPriceForSelectedWorkplace() {
      return isEmptyObject(this.selectedWorkplace)
        ? null
        : this.getFullPriceForWorkplace(this.selectedWorkplace)
    },
    clubPriceForSelectedWorkplace() {
      return isEmptyObject(this.selectedWorkplace)
        ? null
        : this.getClubPriceForWorkplace(this.selectedWorkplace)
    },
    doctorSubtitle() {
      return getDoctorDescription(this.doctorData)
    },
    isSomeWorkplaceInClub() {
      return this.doctorData.workplaces.some(({ isInClub }) => isInClub)
    },
    radioGroupValue() {
      return this.selectedWorkplace.hasSuitableAge ? this.selectedDoctorSpecialityId : null
    },
  },
  actions: {
    updateErrorStateSpeciality(payload) {
      this.isErrorStateSpeciality = payload
    },
    updateAppointmentSpecialitiesRef(payload) {
      this.appointmentSpecialitiesRef = payload
    },
    updateStateSelectedDoctorSpecialityId(payload) {
      this.selectedDoctorSpecialityId = payload
    },
    updateSelectedDoctor(payload) {
      this.selectedDoctor = payload
    },
    resetSelectedDoctor() {
      this.selectedDoctor = getSelectedDoctor()
    },
    validateSelectedSpeciality() {
      this.updateErrorStateSpeciality(!this.radioGroupValue)

      if (!this.radioGroupValue) {
        return this.appointmentSpecialitiesRef
      }
    },
    setStateSelectedDoctorSpecialityId(specId) {
      const clubDataStore = useClubDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      this.updateStateSelectedDoctorSpecialityId(specId)

      setQueryParam('speciality', specId)

      const { isLpu, isClub } = appointmentDataStore.appointmentPlaceTypes

      if (!isLpu && !isClub) {
        return
      }

      const isClubSelectedWorkplace = this.selectedWorkplace.isInClub && clubDataStore.isClubPaymentTypeSelected

      if (isClubSelectedWorkplace) {
        setQueryParam('type', APPOINTMENT_TYPES.club)
      } else {
        deleteQueryParam('type')
      }
    },
  },
})

export default useDoctorDataStore

import { defineStore } from 'pinia'
import { sendYaGoal } from 'components/common/AppointmentPage/functions'
// eslint-disable-next-line import/no-cycle
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'

const useYandexGoalsStore = defineStore('yandexGoalsStore', {
  actions: {
    sendYaGoal({ name, formType, objectType }) {
      const appointmentDataStore = useAppointmentDataStore()
      const { isDirect, isExpress } = appointmentDataStore.appointmentRegistrationTypes
      const { isDoctor, isServices } = appointmentDataStore.appointmentObjectTypes

      if (isDoctor && (objectType === 'doctor' || objectType === 'any')) {
        const {
          isClub,
          isTelemed,
          isTelemedAndTownOnline,
          isTelemedMedtochka,
        } = appointmentDataStore.appointmentPlaceTypes

        const isGoalDirectClub = formType === 'directClub' && isDirect && isClub
        const isGoalDirect = formType === 'direct'
          && isDirect
          && !(isClub || isTelemed || isTelemedMedtochka || isTelemedAndTownOnline)
        const isGoalExpress = formType === 'express' && isExpress
        const isGoalAny = formType === 'any'
        const isGoalDirectTelemed = formType === 'telemed'
          && isDirect
          && (isTelemed || isTelemedMedtochka)
        const isGoalDirectTelemedAndTownOnline = formType === 'telemedAndTownOnline'
          && isDirect
          && isTelemedAndTownOnline
        const isGoalAnyDirect = formType === 'anyDirect' && isDirect

        if (
          isGoalDirectClub
            || isGoalDirect
            || isGoalExpress
            || isGoalAny
            || isGoalDirectTelemed
            || isGoalDirectTelemedAndTownOnline
            || isGoalAnyDirect
        ) {
          sendYaGoal(name)
        }
      }

      if (isServices && (objectType === 'service' || objectType === 'any')) {
        const isGoalDirect = formType === 'direct'
                    && isDirect
                    && !appointmentDataStore.useServiceExpress
        const isGoalExpress = formType === 'express'
                    && (
                      isExpress
                        || appointmentDataStore.useServiceExpress
                    )
        const isGoalAny = formType === 'any'

        if (isGoalDirect || isGoalExpress || isGoalAny) {
          sendYaGoal(name)
        }
      }
    },
  },
})

export default useYandexGoalsStore

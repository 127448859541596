import { defineStore } from 'pinia'
import { addHours } from 'date-fns'
import {
  getCSRFToken,
  createDevNotice,
  serializeObject,
  scrollToElement,
  getQueryParam,
  getTimezoneOffsetInHours,
  isNetworkDisconnect,
  isEmptyObject,
  deleteQueryParam,
  setQueryParam,
} from 'utils'
import { APPOINTMENT_TYPES } from 'www/constants'
import LocationParser from 'modules/LocationParser'
import {
  APPOINTMENT_YANDEX_GOALS,
  CLUB_YANDEX_GOALS,
  TELEMED_YANDEX_GOALS,
  TOWN_ONLINE_GOALS,
} from 'yandexGoals'
import { requestNewAppointment } from 'components/common/AppointmentPage/api'
import {
  getAppointmentRegistrationTypes,
  getAppointmentObjectTypes,
  getInitAdditionalData,
  getAppointmentCallTimes,
  getAppointmentCall,
} from 'components/common/AppointmentPage/interfaces'
import {
  APPOINTMENT_ERRORS,
  APPOINTMENT_TYPE_NAMES,
  APPOINTMENT_PLACE_TYPES,
  USER_ACTION_AFTER_AUTH,
  NEW_APPOINTMENT_URLS,
  PRELOADER_DELAY_FOR_TELEMED_MS,
  APPOINTMENT_TYPE_LABELS,
} from 'components/common/AppointmentPage/constants'
import {
  getSuccessCallTimeTitle,
  getRemainingTimeMessage,
} from 'components/common/AppointmentPage/functions'
import { PAGE_SOURCES } from 'modules/LocationParser/constants'
// eslint-disable-next-line import/no-cycle
import useUserDataStore from 'components/common/AppointmentPage/stores/useUserDataStore'
// eslint-disable-next-line import/no-cycle
import useClubDataStore from 'components/common/AppointmentPage/stores/useClubDataStore'
import useGlobalDataStore from 'components/common/AppointmentPage/stores/useGlobalDataStore'
// eslint-disable-next-line import/no-cycle
import useDoctorDataStore from 'components/common/AppointmentPage/stores/useDoctorDataStore'
// eslint-disable-next-line import/no-cycle
import useServiceDataStore from 'components/common/AppointmentPage/stores/useServiceDataStore'
// eslint-disable-next-line import/no-cycle
import useYandexGoalsStore from 'components/common/AppointmentPage/stores/useYandexGoalsStore'
// eslint-disable-next-line import/no-cycle
import useCalendarDataStore from 'components/common/AppointmentPage/stores/useCalendarDataStore'
import useAuthorizationStore from 'components/common/AppointmentPage/stores/useAuthorizationStore'
// eslint-disable-next-line import/no-cycle
import useFamilyProfilesStore from 'components/common/AppointmentPage/stores/useFamilyProfilesStore'
// eslint-disable-next-line import/no-cycle
import usePreliminaryConsultationStore from 'components/common/AppointmentPage/stores/usePreliminaryConsultationStore'

const locationParserReferrer = new LocationParser({
  testedLocation: 'referrer',
})

const useAppointmentDataStore = defineStore('appointmentDataStore', {
  state: () => ({
    appointmentRegistrationTypes: getAppointmentRegistrationTypes(),
    appointmentObjectTypes: getAppointmentObjectTypes(),
    initAdditionalData: getInitAdditionalData(),
    selectedLpuCallType: 'lpu_choice',
    appointmentCallTimes: getAppointmentCallTimes(),
    appointmentCall: getAppointmentCall(),
    selectedAppointmentCallTime: null,
    startDateAppointment: null,
    appointmentNode: null,
  }),
  getters: {
    appointmentPlaceTypes() {
      return {
        isLpu: !this.initAdditionalData.placeType,
        isHome: this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.home,
        isOnline: this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.online,
        isTelemed: this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.telemed,
        isTelemedAndTownOnline: (
          this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.telemed
                    && getQueryParam('source') === PAGE_SOURCES.doctorListOnline[0]
        ),
        isClub: this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.club,
        isTelemedMedtochka: this.initAdditionalData.placeType === APPOINTMENT_PLACE_TYPES.telemedMedtochka,
      }
    },
    appointmentTypeName() {
      if (this.appointmentRegistrationTypes.isDirect && !this.useServiceExpress) {
        return APPOINTMENT_TYPE_NAMES.direct
      }

      if (this.appointmentRegistrationTypes.isExpress || this.useServiceExpress) {
        return APPOINTMENT_TYPE_NAMES.express
      }
    },
    appointmentTypeLabel() {
      return APPOINTMENT_TYPE_LABELS[this.appointmentTypeName]
    },
    isShowPhoneOnly() {
      const doctorDataStore = useDoctorDataStore()
      const serviceDataStore = useServiceDataStore()

      if (this.appointmentObjectTypes.isDoctor) {
        return doctorDataStore.selectedWorkplace.isShowPhoneOnly
      }

      if (this.appointmentObjectTypes.isServices) {
        return serviceDataStore.serviceData.isShowPhoneOnly
      }
    },
    appointmentCallTimeValidationRules() {
      return [val => !!val || 'Выберите время звонка']
    },
    isValidAppointmentCallTime() {
      if (!this.appointmentCallTimes.times.length) {
        return true
      }

      return this.appointmentCallTimeValidationRules.every(
        validation => validation(this.selectedAppointmentCallTime) === true,
      )
    },
    isClubPossibleOnPage() {
      return this.appointmentRegistrationTypes.isDirect
        && (this.appointmentPlaceTypes.isLpu || this.appointmentPlaceTypes.isClub)
    },
    availableAppointmentCallTimes() {
      const calendarDataStore = useCalendarDataStore()

      const { times: callTimes, minHoursToCall } = this.appointmentCallTimes

      if (calendarDataStore.isLoadingSlots) {
        return []
      }

      if (!calendarDataStore.isAvailableSlot && calendarDataStore.isEmptyCalendarList) {
        return callTimes
      }

      if (!callTimes.length || !calendarDataStore.isAvailableSlot) {
        return []
      }

      const minDateTimeToCall = addHours(calendarDataStore.selectedSlotDate, -minHoursToCall)
      const firstUnavailableTime = callTimes.findIndex(
        ({ date }) => new Date(date) > minDateTimeToCall,
      )

      if (firstUnavailableTime === -1) {
        return callTimes
      }

      return callTimes.slice(0, firstUnavailableTime)
    },
    isTelemedPaymentInfoVisible() {
      return this.appointmentRegistrationTypes.isDirect
        && this.appointmentObjectTypes.isDoctor
        && (this.appointmentPlaceTypes.isTelemed || this.appointmentPlaceTypes.isTelemedMedtochka)
    },
    isAppointmentPaymentInfoVisible() {
      const clubDataStore = useClubDataStore()

      return clubDataStore.isClubSelectTypePaymentVisible
        || clubDataStore.isClubAvailable
        || this.isTelemedPaymentInfoVisible
    },
    useServiceExpressWithoutRelations() {
      const serviceDataStore = useServiceDataStore()
      const calendarDataStore = useCalendarDataStore()
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      const isIgnoreEmptyRelations = !serviceDataStore.doctorEquipmentRelations.length
        && serviceDataStore.countRequestsDoctorEquipmentRelations === 1
      const isIgnoreEmptySlots = calendarDataStore.isEmptyCalendarList
        && !calendarDataStore.isZeroRequests
        && !serviceDataStore.countRequestsDoctorEquipmentRelations

      /**
       * Если зашли в форму, и первым сделали запрос за связками или за слотами,
       * и они в ответе пустые, то пытаемся использовать ЭЗ без связок.
       * */
      return Boolean(
        this.appointmentRegistrationTypes.isAvailableExpress
                && this.appointmentObjectTypes.isServices
                && (isIgnoreEmptyRelations || isIgnoreEmptySlots)
                && !preliminaryConsultationStore.isPreliminaryConsultation,
      )
    },
    useServiceExpress() {
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      return this.useServiceExpressWithoutRelations
        || preliminaryConsultationStore.useServiceExpressWithoutConsultation
    },
  },
  actions: {
    updateStartDateAppointment(payload) {
      this.startDateAppointment = payload
    },
    updateAppointmentCall(payload) {
      this.appointmentCall = payload
    },
    updateSelectedAppointmentCallTime(payload) {
      this.selectedAppointmentCallTime = payload
    },
    updateAppointmentNode(payload) {
      this.appointmentNode = payload
    },
    updateInitAdditionalPlaceType(payload) {
      if (payload === APPOINTMENT_TYPES.lpu) {
        deleteQueryParam('type')
      } else {
        setQueryParam('type', payload)
      }

      this.initAdditionalData.placeType = payload
    },

    async validateAppointmentData() {
      const doctorDataStore = useDoctorDataStore()
      const userDataStore = useUserDataStore()
      const calendarDataStore = useCalendarDataStore()
      const familyProfilesStore = useFamilyProfilesStore()

      // Порядок вызова важен
      const validationMethods = [
        calendarDataStore.validateCalendar(),
        familyProfilesStore.validatePatientCard(),
        userDataStore.validateWarningInfoTelemed(),
        userDataStore.validateCheckboxes(),
      ]

      if (this.appointmentObjectTypes.isDoctor) {
        validationMethods.push(doctorDataStore.validateSelectedSpeciality())
      }

      const validationResults = await Promise.all(validationMethods)
      const scrollElement = validationResults.find(el => el)

      if (scrollElement) {
        scrollToElement(scrollElement)
      }

      return !scrollElement
    },
    async confirmAppointment() {
      const isValid = await this.validateAppointmentData()

      if (!isValid) {
        return
      }

      scrollToElement(this.appointmentNode)

      const clubDataStore = useClubDataStore()

      if (clubDataStore.isClubPaymentTypeSelected) {
        clubDataStore.updateStateIsClubModalVisible(true)
      }

      this.sendAppointmentData()
    },
    appointmentUserPhone(userPhone) {
      return userPhone && userPhone[0] === '7' ? userPhone.slice(1) : userPhone
    },
    sendAppointmentData() {
      const clubDataStore = useClubDataStore()
      const doctorDataStore = useDoctorDataStore()
      const serviceDataStore = useServiceDataStore()
      const yandexGoalsStore = useYandexGoalsStore()
      const calendarDataStore = useCalendarDataStore()
      const authorizationStore = useAuthorizationStore()
      const familyProfilesStore = useFamilyProfilesStore()
      const appointmentDataStore = useAppointmentDataStore()
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      const { selectedPatient } = familyProfilesStore
      const { placeType } = appointmentDataStore.initAdditionalData
      const { userPhone } = authorizationStore
      const {
        name,
        surname,
        birthDay,
        patronymic,
        hasPatronymic,
      } = selectedPatient

      const requestData = {
        patronymic,
        first_name: name,
        last_name: surname,
        birthday: birthDay,
        no_patronymic: !hasPatronymic,
        made_by_other_person: selectedPatient.isOther,
        csrfmiddlewaretoken: getCSRFToken(),
        phone: this.appointmentUserPhone(userPhone),
        no_step: 'no_step', // ToDo - удалить при рефакторинге ПЗ и ЭЗ на backend
        source: locationParserReferrer.source,
        profile_uuid: selectedPatient.uuid,
      }

      if (this.appointmentObjectTypes.isServices) {
        if (serviceDataStore.serviceData.dclass) {
          requestData.service_class = serviceDataStore.serviceData.dclass
        }

        if (!preliminaryConsultationStore.isPreliminaryConsultation) {
          if (doctorDataStore.selectedDoctor.id) {
            requestData.doctor = doctorDataStore.selectedDoctor.id
          }

          if (serviceDataStore.selectedEquipment.id) {
            requestData.equipment = serviceDataStore.selectedEquipment.id
          }
        }

        requestData.lpu = calendarDataStore.initCalendarData.lpuId
      } else if (this.appointmentObjectTypes.isDoctor) {
        Object.assign(requestData, {
          wp: doctorDataStore.selectedWorkplace.id,
          spec: doctorDataStore.selectedDoctorSpecialityId,
        })

        if (
          appointmentDataStore.appointmentPlaceTypes.isTelemed
            || appointmentDataStore.appointmentPlaceTypes.isTelemedMedtochka
        ) {
          requestData.user_timedelta = getTimezoneOffsetInHours()
        }
      }

      if (this.appointmentRegistrationTypes.isDirect && !this.useServiceExpress) {
        const requestDataDirect = {
          mis_comment: appointmentDataStore.selectedLpuCallType,
          slot: calendarDataStore.selectedFullDate,
          is_direct: true,
        }

        if (this.appointmentObjectTypes.isServices) {
          if (clubDataStore.isClubAvailable) {
            requestDataDirect.type = APPOINTMENT_PLACE_TYPES.club
          }

          requestDataDirect.service_type = serviceDataStore.serviceData.dtype

          if (preliminaryConsultationStore.isPreliminaryConsultation) {
            const {
              selectedConsultingDoctor,
              selectedDoctorConsultation,
            } = preliminaryConsultationStore

            if (selectedConsultingDoctor.id) {
              requestDataDirect.doctor = selectedConsultingDoctor.id
            }

            requestDataDirect.synonym = selectedDoctorConsultation.id
            requestDataDirect.service_requires_consultation_id = calendarDataStore.initCalendarData.synonymId
          } else {
            requestDataDirect.synonym = calendarDataStore.initCalendarData.synonymId
          }
        } else if (this.appointmentObjectTypes.isDoctor) {
          const isPossibleToSelectPaymentTab = doctorDataStore.selectedWorkplace.isInClub
            && this.isClubPossibleOnPage

          if (placeType && placeType !== APPOINTMENT_PLACE_TYPES.club) {
            requestDataDirect.type = placeType
          } else if (isPossibleToSelectPaymentTab) {
            if (clubDataStore.isClubPaymentTypeSelected) {
              requestDataDirect.type = APPOINTMENT_PLACE_TYPES.club
              yandexGoalsStore.sendYaGoal({
                name: CLUB_YANDEX_GOALS.selectedPaymentTabClub,
                formType: 'anyDirect',
                objectType: 'doctor',
              })
            } else {
              yandexGoalsStore.sendYaGoal({
                name: CLUB_YANDEX_GOALS.selectedPaymentTabLpu,
                formType: 'anyDirect',
                objectType: 'doctor',
              })
            }
          }
        }

        Object.assign(requestData, requestDataDirect)
      } else if (this.appointmentRegistrationTypes.isExpress || this.useServiceExpress) {
        const requestDataExpress = {
          is_express: true,
        }

        // Такая проверка необходима, т.к. сериализуются любые добавленные свойства
        if (calendarDataStore.selectedFullDate) {
          requestDataExpress.slot = calendarDataStore.selectedFullDate
        }

        if (appointmentDataStore.selectedAppointmentCallTime) {
          requestDataExpress.appointment_time = appointmentDataStore.selectedAppointmentCallTime
        }

        if (this.appointmentObjectTypes.isServices) {
          // Такая проверка необходима, т.к. сериализуются любые добавленные свойства
          if (serviceDataStore.serviceData.dtype) {
            requestDataExpress.service_type = serviceDataStore.serviceData.dtype
          }

          if (calendarDataStore.initCalendarData.synonymId) {
            requestDataExpress.synonym = calendarDataStore.initCalendarData.synonymId
          }
        } else if (this.appointmentObjectTypes.isDoctor) {
          if (placeType && placeType !== APPOINTMENT_PLACE_TYPES.club) {
            requestDataExpress.type = placeType
          }
        }

        Object.assign(requestData, requestDataExpress)
      }

      let request

      if (this.appointmentObjectTypes.isServices) {
        request = requestNewAppointment(NEW_APPOINTMENT_URLS.service, serializeObject(requestData))
      } else if (this.appointmentObjectTypes.isDoctor) {
        this.updateStartDateAppointment(performance.now())
        request = requestNewAppointment(NEW_APPOINTMENT_URLS.doctor, serializeObject(requestData))
      }

      const globalDataStore = useGlobalDataStore()

      globalDataStore.updateStateSendingUserData({ state: true })

      yandexGoalsStore.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.appointmentExpressBeforeSendData,
        formType: 'express',
        objectType: 'any',
      })

      const handleAppointmentRequest = response => {
        if (this.appointmentObjectTypes.isServices) {
          this.handleAppointmentRequest(response)
        } else if (this.appointmentObjectTypes.isDoctor) {
          if (!this.appointmentPlaceTypes.isTelemed && !this.appointmentPlaceTypes.isTelemedMedtochka) {
            this.handleAppointmentRequest(response)
            return
          }

          const appointmentDuration = performance.now() - this.startDateAppointment

          // Если прошло 4 сек. с начала отображения прелоадера, то производим обработку запроса
          if (appointmentDuration >= PRELOADER_DELAY_FOR_TELEMED_MS) {
            this.handleAppointmentRequest(response)
            return
          }

          // Иначе дожидаемся 4х сек. и производим обработку запроса
          setTimeout(() => {
            this.handleAppointmentRequest(response)
          }, PRELOADER_DELAY_FOR_TELEMED_MS - appointmentDuration)
        }
      }

      request
        .then(handleAppointmentRequest)
        .catch(rejectedData => handleAppointmentRequest(rejectedData.response, rejectedData, requestData))
    },
    handleAppointmentRequest(response = {}, rejectedData = {}, requestData = {}) {
      const authorizationStore = useAuthorizationStore()
      const clubDataStore = useClubDataStore()
      const globalDataStore = useGlobalDataStore()
      const yandexGoalsStore = useYandexGoalsStore()
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      const { data: responseData, status: statusCode } = response

      globalDataStore.updateStateSendingUserData({ state: false })

      if (isNetworkDisconnect({ status: statusCode, message: '' })) {
        globalDataStore.updateErrorData({
          isEnabled: true,
          isDialogRender: true,
          ...APPOINTMENT_ERRORS.notNetwork,
        })
        return
      }

      if (statusCode === 403) {
        globalDataStore.updateErrorData({
          isEnabled: true,
          isDialogRender: true,
          isPersistent: true,
          ...APPOINTMENT_ERRORS.notAuthorization,
        })

        authorizationStore.updateUserActionAfterAuthorization(USER_ACTION_AFTER_AUTH.sendAppointmentForm)
        clubDataStore.updateStateIsClubModalVisible(false)

        return
      }

      if (!responseData) {
        globalDataStore.showDefaultError()

        const { statusText } = response
        let rejectDescription = ''

        if (statusCode && statusText) {
          rejectDescription = `${statusCode} - ${statusText}`
        } else if (!isEmptyObject(response)) {
          rejectDescription = JSON.stringify(response)
        } else if (!isEmptyObject(rejectedData)) {
          rejectDescription = JSON.stringify(rejectedData)
        } else {
          rejectDescription = 'неизвестно'
        }

        createDevNotice({
          description: `Данные об ошибке: ${rejectDescription}; Данные запроса: ${JSON.stringify(requestData)}`,
          method: 'handleAppointmentRequest',
          module: 'AppointmentPage/useAppointmentDataStore',
        })

        return
      }

      const {
        window_state: windowState,
        data: appointmentData = {},
      } = responseData
      const {
        coupon_request: coupon,
        call: appointmentCall,
      } = appointmentData

      if (clubDataStore.isClubModalVisible && (statusCode !== 200 || !coupon)) {
        clubDataStore.updateStateIsClubModalVisible(false)
      }

      if (statusCode === 200) {
        if (clubDataStore.isClubModalVisible && coupon) {
          clubDataStore.updateCouponData({
            id: coupon.id,
            dateCreated: coupon.dt_created,
            lifeTime: coupon.LIFETIME_MINUTES,
            clubCouponPrice: coupon.club_coupon_price,
            price: coupon.appointment_price,
            fullPrice: coupon.full_appointment_price,
            clubLpuPrice: coupon.club_price_in_lpu,
            cardPrice: coupon.card_price,
            totalPrice: coupon.total_price,
            clubCardLifetimeMonths: coupon.CLUB_CARD_LIFETIME_MONTHS,
          })

          // Сохранить данные экрана успеха для случая, если пользователь нажмёт "Пойду без скидки"
          if (windowState && this.appointmentObjectTypes.isDoctor) {
            globalDataStore.updateSuccessData({
              medtochkaUrl: windowState.medtochka_url,
              title: windowState.title,
              subtitle: windowState.message,
              buttonState: windowState.button_state,
            })
          }

          return
        }

        if (this.appointmentObjectTypes.isDoctor) {
          yandexGoalsStore.sendYaGoal({
            name: 'common_appointment_page__direct_success_message',
            formType: 'anyDirect',
            objectType: 'doctor',
          })
          yandexGoalsStore.sendYaGoal({
            name: APPOINTMENT_YANDEX_GOALS.successDirect,
            formType: 'directClub',
            objectType: 'doctor',
          })
          yandexGoalsStore.sendYaGoal({
            name: APPOINTMENT_YANDEX_GOALS.successDirect,
            formType: 'direct',
            objectType: 'doctor',
          })
          yandexGoalsStore.sendYaGoal({
            name: TOWN_ONLINE_GOALS.redirectedToTelemedPayment,
            formType: 'telemedAndTownOnline',
            objectType: 'doctor',
          })

          if (
            (this.appointmentPlaceTypes.isTelemed || this.appointmentPlaceTypes.isTelemedMedtochka)
            && windowState
            && windowState.medtochka_url
          ) {
            yandexGoalsStore.sendYaGoal({
              name: TELEMED_YANDEX_GOALS.appointmentSuccess,
              formType: 'telemed',
              objectType: 'doctor',
            })
            window.location.assign(windowState.medtochka_url)

            return
          }
        }

        if (appointmentCall && appointmentCall.status === 0) {
          const { call_datetime: callDatetime } = appointmentCall

          globalDataStore.updateSuccessData({
            isEnabled: true,
            title: getSuccessCallTimeTitle(callDatetime),
            subtitle: 'Если пропустите этот звонок, перезвоните по номеру:',
            showPhone: true,
            buttonState: {
              get: 'understandAndAppointmentMore',
              post: 'understandAndAppointmentMore',
            },
          })

          yandexGoalsStore.sendYaGoal({
            name: APPOINTMENT_YANDEX_GOALS.expressStepLast,
            formType: 'express',
            objectType: 'doctor',
          })
          yandexGoalsStore.sendYaGoal({
            name: APPOINTMENT_YANDEX_GOALS.serviceSuccessExpress,
            formType: 'express',
            objectType: 'service',
          })

          return
        }

        if (appointmentCall && appointmentCall.status >= 0) {
          this.updateAppointmentCall({
            ...appointmentCall,
            isDialogRender: true,
          })
          return
        }

        if (windowState) {
          const { name_from_mis: doctorNameFromMIS } = appointmentData.doctor || {}
          const { name_from_mis: equipmentNameFromMIS } = appointmentData.equipment || {}

          globalDataStore.updateSuccessData({
            isEnabled: true,
            medtochkaUrl: windowState.medtochka_url,
            title: windowState.title,
            subtitle: windowState.message,
            buttonState: windowState.button_state,
            doctorNameFromMIS,
            equipmentNameFromMIS,
          })

          return
        }

        globalDataStore.updateSuccessDataIsEnabled(true)

        return
      }

      if (!windowState) {
        globalDataStore.showDefaultError()

        const { statusText } = response

        createDevNotice({
          description: `${statusCode}: ${statusText}; ${JSON.stringify(responseData)}`,
          method: 'handleAppointmentRequest',
          module: 'AppointmentPage/useAppointmentDataStore',
        })

        return
      }

      const errorData = {
        isDialogRender: true,
        isEnabled: true,
        code: windowState.code,
        title: windowState.title,
        subtitle: windowState.message,
        buttonState: windowState.button_state,
        isPersistent: false,
        redirectUrl: '',
      }

      if (windowState.code === 'patient_in_blacklist') {
        errorData.isDialogRender = false
      } else if (windowState.code === 'simultaneous_appointment') {
        errorData.subtitle = getRemainingTimeMessage(windowState.remaining_time)
      } else if (windowState.code === 'clinic_turn_off_service_appointment') {
        errorData.isDialogRender = false
      } else if (
        windowState.code === 'slot_does_not_exist'
        && this.appointmentObjectTypes.isServices
      ) {
        errorData.buttonState = {
          get: 'reloadClose',
          post: 'reloadClose',
        }
      } else if (windowState.code === 'services_consultation_irrelevant_doctor') {
        // Чтобы ошибка не выводилась дважды, игнорируем единожды её вывод на фронте
        preliminaryConsultationStore.updateIsIgnoreErrorConsultationNotAvailable(true)
      } else if (windowState.code === 'services_consultation_not_single_doctors_left') {
        errorData.isPersistent = true
      } else if (windowState.code === 'has_future_appointment') {
        errorData.buttonState = {
          get: 'understandWithRedirect',
          post: 'understandWithRedirect',
        }
        errorData.redirectUrl = windowState.additional_info.appointment.medtochka_url
      }

      globalDataStore.updateErrorData(errorData)
    },
  },
})

export default useAppointmentDataStore

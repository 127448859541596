import { defineStore } from 'pinia'
import { isEmptyObject } from 'utils'
import { apiScheduleTelemed } from 'www/api'
import { AppointmentModules } from 'modules/appointment-modules'
import { RECURSIVE_INTERVAL_SCHEDULE_CHECKS_DOCTOR_MS } from 'components/common/AppointmentPage/constants'
import {
  TestHasTimeOptions,
  RequestRecursiveDoctorScheduleOptions,
  StoreStateDoctorAutomaticScheduleChecks,
  ResponseDoctorScheduleData,
} from 'components/common/AppointmentPage/types'

export const useDoctorAutomaticScheduleChecks = defineStore('doctorAutomaticScheduleChecks', {
  state: (): StoreStateDoctorAutomaticScheduleChecks => ({
    slots: undefined,
    useStopRequest: false,
    customRequestLimit: 0,
    currentRequestAttempt: 0,
  }),
  getters: {
    isAttemptsOver(): boolean {
      return this.currentRequestAttempt >= this.customRequestLimit - 1
    },
  },
  actions: {
    testHasTime({ selectedDate }: TestHasTimeOptions): boolean {
      try {
        if (!this.slots) {
          return false
        }

        const currentDayData = this.slots[selectedDate.day]

        if (!currentDayData) {
          return false
        }

        return Boolean(
          currentDayData.find(({ time }: { time: string }) => time === selectedDate.time),
        )
      } catch {
        return false
      }
    },
    async requestRecursiveDoctorSchedule(options: RequestRecursiveDoctorScheduleOptions): Promise<void> {
      try {
        const {
          requestData,
          requestDataTelemed,
          afterAll,
          afterEach,
        } = options
        const { getAppointmentDoctorsSlots } = new AppointmentModules()
        const reset = () => {
          if (afterAll) {
            afterAll()
          }

          this.useStopRequest = false
          this.currentRequestAttempt = 0
        }

        if (this.useStopRequest) {
          reset()
          return
        }

        if (this.isAttemptsOver) {
          reset()
          return
        }

        this.currentRequestAttempt += 1

        let slots: ResponseDoctorScheduleData['result'][number]['slots'] = {}

        if (requestDataTelemed) {
          const responseData = (await apiScheduleTelemed.post(requestDataTelemed)).data

          slots = apiScheduleTelemed.transformSlots(responseData[0].schedule_data[0].schedule)
        } else if (requestData) {
          // @ts-ignore
          const responseData = await getAppointmentDoctorsSlots(requestData)

          slots = responseData?.result?.[0].slots
        } else {
          return
        }

        this.slots = slots

        if (!this.slots || isEmptyObject(this.slots)) {
          reset()
          return
        }

        const isEveryEmptySlots = Object.values(this.slots).every(item => !item.length)

        if (isEveryEmptySlots) {
          reset()
          return
        }

        window.setTimeout(async () => {
          await this.requestRecursiveDoctorSchedule(options)
        }, RECURSIVE_INTERVAL_SCHEDULE_CHECKS_DOCTOR_MS)

        if (afterEach) {
          afterEach()
        }
      } catch {
        if (options.afterAll) {
          options.afterAll()
        }

        this.useStopRequest = false
        this.currentRequestAttempt = 0
      }
    },
  },
})

<template>
  <VApp
    class="club-discount-info-bottom-sheet"
    data-club-discount-info-bottom-sheet
  >
    <VBottomSheet
      v-if="isMobile"
      :value="value"
      persistent
      @input="$emit('input', $event)"
    >
      <VCard class="px-4 py-6">
        <div class="ui-text ui-text_h6 ui-kit-color-text">
          Как работает клубная цена
        </div>

        <ClubDiscountInfo
          class="mt-6"
          :full-price="fullPrice"
          :club-offer-data="clubOfferData"
          club-enabled
        />

        <VBtn
          class="ui-kit-bg-bg-primary mt-6"
          color="primary"
          block
          text
          @click="$emit('input', false)"
        >
          Хорошо
        </VBtn>
      </VCard>
    </VBottomSheet>
    <VDialog
      v-else
      :value="value"
      max-width="476"
      persistent
      @input="$emit('input', $event)"
    >
      <VCard elevation="0">
        <div class="px-2 py-4">
          <div class="d-flex">
            <VIcon @click="$emit('input', false)">
              ui-icon-close-not-a-circle
            </VIcon>
            <div class="ui-text ui-text_h6 ui-kit-color-text text-no-wrap ml-8">
              Как работает клубная цена
            </div>
          </div>
        </div>
        <VDivider />
        <div class="pa-6">
          <div
            v-if="clubOfferData.cardDateEnd"
            class="ui-kit-color-text-secondary ui-text ui-text_body-1 mb-6"
          >
            Вы в клубе до {{ clubOfferData.cardDateEnd }} 👑
          </div>
          <ClubDiscountInfo
            :full-price="fullPrice"
            :club-offer-data="clubOfferData"
            club-enabled
          />

          <VBtn
            class="ui-kit-bg-bg-primary mt-6"
            color="primary"
            block
            text
            @click="$emit('input', false)"
          >
            Хорошо
          </VBtn>
        </div>
      </VCard>
    </VDialog>
  </VApp>
</template>

<script>
import { scrollLock } from 'utils'
import vuetify from 'modules/vuetify-plugin'
import { getDefaultClubOfferDataPropInterface } from 'components/common/Club/components/core/ClubDiscount/models'
import ClubDiscountInfo from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountInfo'

export default {
  name: 'ClubDiscountInfoBottomSheet',
  components: {
    ClubDiscountInfo,
  },
  vuetify,
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    fullPrice: {
      type: Number,
      required: true,
    },
    clubOfferData: {
      type: Object,
      default: getDefaultClubOfferDataPropInterface,
    },
  },
  computed: {
    isMobile: () => window.MOBILE_VERSION,
  },
  watch: {
    value(newValue) {
      if (newValue) {
        scrollLock.enable()
      } else {
        scrollLock.disable()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-club-discount-info-bottom-sheet] {
  @include v-app-reset;
}
</style>

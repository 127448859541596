<template>
  <VSheet
    class="d-flex align-center rounded-t-lg px-4"
    :class="[isFilterListVisible ? 'pt-4 pb-2' : isMobile ? 'py-4' : 'py-2']"
    color="primary"
  >
    <div>
      <div class="ui-kit-color-bg-gray-0 ui-text ui-text_h6">
        Запись на приём
      </div>
      <AppointmentTypeFilterList
        v-if="isFilterListVisible"
        :place-types="placeTypes"
      />
    </div>
    <VSpacer />
    <VBtn
      v-if="!isMobile && filterType !== 'telemed'"
      class="ui-kit-color-primary align-self-end"
      color="uiKitBgPrimary"
      depressed
      @click="handlePopupMapButtonClick"
    >
      <VIcon class="mr-2">
        ui-icon-place
      </VIcon>
      На карте
    </VBtn>
  </VSheet>
</template>

<script setup lang="ts">
import type { PlaceType } from 'components/common/DoctorPriceBlock/types'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { dispatchCustomEventGlobally } from 'utils'
import { POPUP_MAP_EVENTS } from 'www/constants'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
// eslint-disable-next-line import/extensions
import AppointmentTypeFilterList from 'components/common/DoctorPageLpuList/components/AppointmentTypeFilterList/AppointmentTypeFilterList.vue'
import { PLACE_TYPES } from 'components/common/DoctorPriceBlock/constants'

const externalDataStore = useExternalDataStore()
const { lpuAddressList, isMobile } = storeToRefs(externalDataStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType, lpuAddressListFiltered } = storeToRefs(doctorPageLpuListStore)

const appointmentTypeFilterListLpuAddresses = computed(() => lpuAddressList.value?.map(lpuAddress => ({
  workplaces: lpuAddress.workplaces.map(workplace => ({
    hasPrice: {
      clinic: typeof workplace.price === 'number',
      home: typeof workplace.price_go === 'number',
      telemed: (workplace.appointment_telemed && typeof workplace.pd_price_online === 'number') || (
        window.FEATURE_FLAGS.pd_medtochka_telemed
          && workplace.appointment_telemed_in_medtochka
          && typeof workplace.mt_price_online === 'number'
      ),
    },
  })),
})) || [])

function isWorkplaceHasAppointmentType(
  appointmentType: PlaceType,
  workplace: typeof appointmentTypeFilterListLpuAddresses.value[number]['workplaces'][number],
) {
  return workplace.hasPrice[appointmentType]
}

function isLpuHasAppointmentType(
  appointmentType: PlaceType,
  lpuAddress: typeof appointmentTypeFilterListLpuAddresses.value[number],
) {
  return lpuAddress.workplaces
    ? lpuAddress.workplaces.some(workplace => isWorkplaceHasAppointmentType(appointmentType, workplace))
    : false
}

function isSomeLpuHasAppointmentType(appointmentType: PlaceType) {
  return appointmentTypeFilterListLpuAddresses.value.some(
    lpuAddress => isLpuHasAppointmentType(appointmentType, lpuAddress),
  )
}

const placeTypes = PLACE_TYPES.filter(placeType => isSomeLpuHasAppointmentType(placeType))

const isFilterListVisible = placeTypes.length > 1

const eventNameOpenPopup = isMobile.value ? POPUP_MAP_EVENTS.openMobile : POPUP_MAP_EVENTS.openDesktop

function handlePopupMapButtonClick() {
  window.history.pushState(null, '', new URL('', window.location.href))

  dispatchCustomEventGlobally(eventNameOpenPopup, {
    lpusData: lpuAddressListFiltered.value.map(lpuAddress => ({
      coords: [lpuAddress.lat, lpuAddress.lon],
      name: lpuAddress.lpu.name,
      address: lpuAddress.address || lpuAddress.lpu.address,
      id: String(lpuAddress.lpu.id),
      phone: lpuAddress.lpu.lpuphone?.phone || undefined,
    })),
  })
}
</script>

<template>
  <VBtn
    data-qa="appointment_form_appointment_button"
    color="primary"
    elevation="0"
    :class="{ 'appointment-button_club-gradient': clubGradient }"
    :disabled="!isFamilyRequestDone"
    block
    @click="handleAppointment"
  >
    <slot>
      <VIcon
        v-if="iconName && !appointmentPlaceTypes.isTelemed && !appointmentPlaceTypes.isTelemedMedtochka"
        class="mr-2"
      >
        {{ iconName }}
      </VIcon>
      {{ appointmentPlaceTypes.isTelemed || appointmentPlaceTypes.isTelemedMedtochka ? 'К оплате' : 'Записаться' }}
    </slot>
  </VBtn>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'
import { APPOINTMENT_ERRORS } from 'components/common/AppointmentPage/constants'
import {
  useGlobalDataStore,
  useYandexGoalsStore,
  useCalendarDataStore,
  useFamilyProfilesStore,
  useAppointmentDataStore,
} from 'components/common/AppointmentPage/stores'

export default {
  name: 'AppointmentButton',
  props: {
    clubGradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useFamilyProfilesStore, [
      'isFamilyRequestDone',
    ]),
    ...mapState(useAppointmentDataStore, [
      'appointmentPlaceTypes',
      'appointmentTypeName',
      'appointmentObjectTypes',
      'appointmentRegistrationTypes',
    ]),
    ...mapState(useCalendarDataStore, [
      'isEmptyCalendarList',
    ]),
    iconName() {
      return { direct: 'ui-icon-flashlight', express: 'ui-icon-phone-none' }[this.appointmentTypeName]
    },
  },
  methods: {
    handleAppointment() {
      if (
        window.FEATURE_FLAGS.pd_automatic_schedule_checks
        && this.appointmentObjectTypes.isDoctor
        && this.isEmptyCalendarList
        && this.appointmentRegistrationTypes.isDirect
      ) {
        this.updateErrorData({
          isEnabled: true,
          isPersistent: true,
          isDialogRender: true,
          ...APPOINTMENT_ERRORS.appointmentDoctorOnlineNotWork,
        })

        return
      }

      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.clickAppointmentDirect,
        formType: 'anyDirect',
        objectType: 'doctor',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.serviceClickAppointmentDirect,
        formType: 'direct',
        objectType: 'service',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.clickAppointmentExpress,
        formType: 'express',
        objectType: 'doctor',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.serviceClickAppointmentExpress,
        formType: 'express',
        objectType: 'service',
      })

      this.confirmAppointment()
    },
    ...mapActions(useAppointmentDataStore, [
      'confirmAppointment',
    ]),
    ...mapActions(useYandexGoalsStore, [
      'sendYaGoal',
    ]),
    ...mapActions(useGlobalDataStore, [
      'updateErrorData',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.appointment-button {
  &_club-gradient {
    background-color: $ui-kit-text-warning;
    background-image: $ui-kit-club-gradient;
  }
}
</style>

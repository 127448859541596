<template>
  <VExpansionPanel v-if="isMobile && doctor">
    <VExpansionPanelHeader
      class="px-4"
      expand-icon="ui-icon-arrow-down"
    >
      <div>
        <div class="ui-text ui-text_subtitle-1">
          {{ lpuAddress.lpu.name }}
        </div>
        <template v-if="filterType !== 'telemed'">
          <VExpandTransition>
            <div
              v-if="!isExpanded"
              class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
            >
              {{ fullAddress }}
            </div>
          </VExpandTransition>
        </template>
      </div>
    </VExpansionPanelHeader>
    <VExpansionPanelContent>
      <WorkplaceOrServiceSelect
        v-if="initWorkplaces.length || initServices.length"
        :lpu-id="lpuAddress.lpu.id"
        :data-lpu-addr-id="lpuAddress.id"
        :init-speciality-translit="doctor.spec.translit"
        :init-workplaces="initWorkplaces"
        :init-services="initServices"
      />
      <DoctorPriceBlockItemList
        v-if="selectedItem.priceList"
        v-show="filterType !== 'home'"
        :page-type="filterType"
        :price="/* @ts-ignore */selectedItem.priceList[translatedChosenFilter]"
        :club-price="/* @ts-ignore */selectedItem.priceList.priceClub"
        :telemed-medtochka-price="/* @ts-ignore */selectedItem.priceList.priceMedtochkaTelemed"
        speciality-selected
        @update:redirect-value="handlePriceBlockUpdateRedirectValue"
        @update:telemed-medtochka-state="handlePriceBlockUpdateTelemedMedtochkaState"
      />
      <WorkplaceOrServiceCalendar
        v-if="initWorkplaces.length || initServices.length"
        class="my-3"
        :lpu-address="lpuAddress"
      />
      <template v-if="filterType !== 'telemed'">
        <div
          v-ripple
          class="d-flex align-center ui-text ui-text_body-1 py-2 cursor-pointer"
          @click="handlePopupMapButtonClick"
        >
          <VIcon
            size="20"
            class="mr-4"
            color="uiKitIconSecondary"
          >
            ui-icon-map-pin
          </VIcon>
          {{ fullAddress }}
          <VSpacer />
          <VIcon>
            ui-icon-arrow-right
          </VIcon>
        </div>
        <DoctorPageLpuListMetroList
          v-if="lpuAddress.lpu.lpumetro_set.all.length"
          class="mt-2"
          :lpu-address="lpuAddress"
        />
      </template>
      <DoctorPageLpuListPhone :lpu-address="lpuAddress" />
      <a
        v-ripple
        :href="lpuUrl"
        class="d-flex align-center text-decoration-none ui-text ui-text_body-1 py-2 mt-2"
      >
        <VIcon
          size="20"
          class="mr-4"
          color="uiKitIconSecondary"
        >
          ui-icon-home-ProDoctorov
        </VIcon>
        Страница клиники
        <VSpacer />
        <VIcon>
          ui-icon-arrow-right
        </VIcon>
      </a>
      <BannerMtLink
        v-if="isBannerMtLinkVisible"
        class="mt-4"
        :text="'К врачу можно приходить с\u00A0электронной медкартой'"
      />
    </VExpansionPanelContent>
  </VExpansionPanel>
  <div
    v-else-if="doctor"
    class="doctor-page-list-lpu pa-6"
  >
    <BannerMtLink
      v-if="isBannerMtLinkVisible"
      class="mb-6"
      :text="'К врачу можно приходить с\u00A0электронной медкартой'"
    />
    <div class="d-flex">
      <VSheet
        class="d-flex flex-column"
        width="336"
      >
        <a
          class="ui-text ui-text_subtitle-1 ui-kit-color-primary text-decoration-none"
          :href="lpuUrl"
        >{{ lpuAddress.lpu.name }}</a>
        <div
          v-if="isUserStaff"
          class="mt-4"
        >
          <a
            class="adminka"
            :href="`${urls?.problemsWithSlots}?lpu_id=${lpuAddress.lpu.id}&doctor_id=${lpuAddress.doctor_id}`"
            target="_blank"
            rel="noopener noreferrer"
          >Проблемы с расписанием</a>
        </div>
        <WorkplaceOrServiceSelect
          v-if="initWorkplaces.length || initServices.length"
          class="mt-6"
          label="Специальность или услуга"
          :lpu-id="lpuAddress.lpu.id"
          :data-lpu-addr-id="lpuAddress.id"
          :init-speciality-translit="doctor.spec.translit"
          :init-workplaces="initWorkplaces"
          :init-services="initServices"
        />
        <DoctorPriceBlockItemList
          v-if="selectedItem.priceList"
          v-show="filterType !== 'home'"
          :page-type="filterType"
          :price="/* @ts-ignore */selectedItem.priceList[translatedChosenFilter]"
          :club-price="/* @ts-ignore */selectedItem.priceList.priceClub"
          :telemed-medtochka-price="/* @ts-ignore */selectedItem.priceList.priceMedtochkaTelemed"
          speciality-selected
          @update:redirect-value="handlePriceBlockUpdateRedirectValue"
          @update:telemed-medtochka-state="handlePriceBlockUpdateTelemedMedtochkaState"
        />
        <template v-if="filterType !== 'telemed'">
          <div
            v-ripple
            class="d-flex align-center ui-text ui-text_body-1 ui-kit-color-primary py-2 cursor-pointer mt-4"
            @click="handlePopupMapButtonClick"
          >
            <VIcon
              size="20"
              class="mr-4"
              color="uiKitIconSecondary"
            >
              ui-icon-map-pin
            </VIcon>
            <template v-if="isUserStaff">
              <div>
                <span class="ui-kit-color-text">
                  {{ lpuAddress.lpu.town.translit !== townTranslit ? `${props.lpuAddress.lpu.town.name},` : '' }}
                </span>
                {{ lpuAddress.address }}
              </div>
            </template>
            <template v-else>
              {{ fullAddress }}
            </template>
          </div>
          <DoctorPageLpuListMetroList
            v-if="lpuAddress.lpu.lpumetro_set.all.length"
            class="mt-2"
            :lpu-address="lpuAddress"
          />
        </template>
        <DoctorPageLpuListPhone :lpu-address="lpuAddress" />
      </VSheet>
      <VSpacer />
      <VSheet width="389">
        <WorkplaceOrServiceCalendar
          v-if="initWorkplaces.length || initServices.length"
          :lpu-address="lpuAddress"
        />
      </VSheet>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import { storeToRefs } from 'pinia'
import { computed, nextTick } from 'vue'
import { dispatchCustomEventGlobally } from 'utils'
import { POPUP_MAP_EVENTS, type RedirectValue } from 'www/constants'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import {
  WorkplaceOrServiceCalendar,
  WorkplaceOrServiceSelect,
} from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
/* eslint-disable import/extensions */
import DoctorPriceBlockItemList from 'components/common/DoctorPriceBlock/components/DoctorPriceBlockItemList.vue'
import DoctorPageLpuListMetroList from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListMetroList.vue'
import DoctorPageLpuListPhone from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListPhone.vue'
import BannerMtLink from 'components/common/BannerMtLink/components/common/BannerMtLink.vue'
/* eslint-enable import/extensions */

const props = defineProps<{
  lpuAddress: LpuAddressList[number]
  isExpanded?: boolean
}>()

const externalDataStore = useExternalDataStore()
const {
  currentDate,
  doctor,
  synonymPriceList,
  townTranslit,
  urls,
  isMobile,
} = storeToRefs(externalDataStore)

const initWorkplaces = computed(
  () => props.lpuAddress.workplaces
    .filter(workplace => workplace.price !== null)
    .map(workplace => ({
      id: workplace.id,
      doctorId: props.lpuAddress.doctor_id,
      isInClub: workplace.in_club,
      speciality: {
        id: workplace.spec.id,
        name: workplace.spec.name,
        translit: workplace.spec.translit,
      },
      priceList: {
        price: workplace.price,
        priceHome: workplace.price_go,
        priceOnline: workplace.appointment_telemed ? workplace.pd_price_online : null,
        priceMedtochkaTelemed: workplace.appointment_telemed_in_medtochka ? workplace.mt_price_online : null,
        priceClub: workplace.club_price,
      },
      age: {
        min: workplace.age_min,
        max: workplace.age_max,
      },
    })) || [],
)

const initServices = computed(
  () => synonymPriceList.value
    ?.filter(synonymPrice => synonymPrice.lpu.id === props.lpuAddress.lpu.id)
    .map(synonymPrice => ({
      id: synonymPrice.id,
      synonymId: synonymPrice.synonym_id,
      doctorId: synonymPrice.doctor_id,
      name: synonymPrice.name,
      price: synonymPrice.price,
      age: {
        min: synonymPrice.age_min,
        max: synonymPrice.age_max,
      },
    })) || [],
)

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuAddress.lpu.id)()

doctorAppointmentCardStore.lpuId = props.lpuAddress.lpu.id
doctorAppointmentCardStore.timedelta = props.lpuAddress.lpu.town.timedelta || 0
doctorAppointmentCardStore.hasSlots = props.lpuAddress.has_slots

if (currentDate) {
  doctorAppointmentCardStore.nowDateString = currentDate.value
}

const { selectedItem, translatedChosenFilter, redirectValue } = storeToRefs(doctorAppointmentCardStore)
const { requestCalendarSlots } = doctorAppointmentCardStore

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType } = storeToRefs(doctorPageLpuListStore)

function handlePriceBlockUpdateRedirectValue(newRedirectValue: RedirectValue) {
  redirectValue.value = newRedirectValue
}

async function handlePriceBlockUpdateTelemedMedtochkaState() {
  /**
   * nextTick нужен, чтобы установились значения selectedItem, которые используются в useDoctorAppointmentCardStore
   */
  await nextTick()
  await requestCalendarSlots()
}

const eventNameOpenPopup = isMobile.value ? POPUP_MAP_EVENTS.openMobile : POPUP_MAP_EVENTS.openDesktop

function handlePopupMapButtonClick() {
  window.history.pushState(null, '', new URL('', window.location.href))
  dispatchCustomEventGlobally(eventNameOpenPopup, {
    lpusData: [{
      coords: [props.lpuAddress.lat, props.lpuAddress.lon],
      name: props.lpuAddress.lpu.name,
      address: props.lpuAddress.address,
      id: String(props.lpuAddress.lpu.id),
    }],
  })
}

const lpuUrl = computed(() => {
  const { lpu } = props.lpuAddress

  if (!urls.value || !lpu.translit) {
    return '/'
  }

  return urls.value.$lpu
    .replace('lpu-town-translit', lpu.town.translit)
    .replace('lpu-translit', lpu.translit)
})

const fullAddress = computed(() => `${
  props.lpuAddress.lpu.town.translit !== townTranslit.value
    ? `${props.lpuAddress.lpu.town.name},`
    : ''
} ${props.lpuAddress.address}`)

const isUserStaff = window.USER.isStaff

const isBannerMtLinkVisible = computed(() => props.lpuAddress.lpu.uses_mtlink && filterType.value === 'clinic')
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

:deep(.v-expansion-panel-content__wrap) {
  padding-left: 16px;
  padding-right: 16px;
}

.doctor-page-list-lpu {
  &:not(:last-child) {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }
}
</style>

<template>
  <component
    :is="isMobile ? VBottomSheet : SideMenu"
    :value="value"
    v-bind="isMobile ? {
      fullscreen: true,
    } : {
      useAlternateContentTemplate: true,
    }"
    v-on="isMobile ? {} : {
      'side-menu:click-close': () => emit('input', false),
    }"
    @input="emit('input', $event)"
  >
    <div class="d-flex flex-column uiKitBgGray0 h-100">
      <div>
        <div class="px-2 py-4">
          <VIcon @click="emit('input', false)">
            ui-icon-close-not-a-circle
          </VIcon>
        </div>
        <VTextField
          :value="searchText"
          background-color="uiKitBgGray40"
          color="uiKitTextInfo"
          clear-icon="ui-icon-close-not-a-circle"
          prepend-inner-icon="ui-icon-search"
          label="Специальность или услуга"
          class="px-2 mb-2"
          clearable
          hide-details
          solo
          flat
          @input="debounceUpdateSearchText"
        />
      </div>
      <VDivider />
      <div class="flex-grow-1 overflow-y-auto">
        <template v-if="workplacesList.length || servicesList.length">
          <ContentList
            title="Приём"
            :lpu-id="lpuId"
            :class="{ 'mb-4': servicesList.length }"
            :list="/* @ts-ignore */workplacesList"
            :is-lpu-appointment-type-chosen="isLpuAppointmentTypeChosen"
            @click-item="handleClickItem"
          />
          <ContentList
            title="Услуги"
            :lpu-id="lpuId"
            :list="/* @ts-ignore */servicesList"
            is-lpu-appointment-type-chosen
            @click-item="handleClickItem"
          />
        </template>
        <div
          v-else
          class="text-center py-20 px-2 my-2"
        >
          <img
            src="/static/_v1/pd/pictures/ui-kit/search.svg"
            alt="Поиск"
            width="150"
            height="150"
            class="mb-2"
          >
          <div class="ui-text ui-text_body-1 uiKitText--text">
            Ничего не найдено
          </div>
        </div>
      </div>
    </div>
  </component>
</template>

<script setup lang="ts">
import type { Service, Workplace } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/types'
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { debounce, getFormattedPrice } from 'utils'
import { getWorkplaceNonNullPrices, getSpecialityAgesText } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/functions'
// eslint-disable-next-line import/extensions
import ContentList, { type Item } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/components/ContentList.vue'
import { VBottomSheet } from 'vuetify/lib'
// eslint-disable-next-line import/extensions
import SideMenu from 'components/desktop/core/SideMenu.vue'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'

const isMobile = window.MOBILE_VERSION

const props = defineProps<{
  value: boolean
  lpuId: number
  initWorkplaces: Array<Workplace>
  initServices: Array<Service>
}>()

const emit = defineEmits(['input', 'item-selected'])

const searchText = ref('')

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType } = storeToRefs(doctorPageLpuListStore)

const isLpuAppointmentTypeChosen = computed(() => filterType.value === 'clinic')

function checkIncludeSearchText(text: string) {
  return text.toLowerCase().includes(searchText.value.toLowerCase())
}

const workplaces = computed(() => {
  if (!searchText.value) {
    return props.initWorkplaces
  }

  return props.initWorkplaces.filter(
    ({ speciality }) => checkIncludeSearchText(speciality.name),
  )
})

function updateSearchText(text: string) {
  searchText.value = text || ''
}

const services = computed(() => {
  if (!searchText.value) {
    return props.initServices
  }

  return props.initServices.filter(({ name }) => checkIncludeSearchText(name))
})

const workplacesList = computed(() => workplaces.value.map(workplace => {
  const notNullPrices = getWorkplaceNonNullPrices(workplace.priceList)

  return {
    ...workplace,
    name: workplace.speciality.name,
    price: getFormattedPrice(Math.min(...notNullPrices)),
    agesText: getSpecialityAgesText(workplace.age),
    isWorkplace: true,
  }
}))

const servicesList = computed(() => services.value.map(service => ({
  ...service,
  price: service.price ? getFormattedPrice(service.price) : null,
  agesText: getSpecialityAgesText(service.age),
  isWorkplace: false,
})))

const debounceUpdateSearchText = debounce(updateSearchText, 200)

function handleClickItem(item: Item) {
  if (!props.value) {
    return
  }

  emit('item-selected', item)
  emit('input', false)
}

watch(() => props.value, newValue => {
  if (!newValue) {
    searchText.value = ''
  }
})
</script>

<style scoped lang="scss">
// Потому что есть глобальные стили с селектором `#app input`, которые ломают отображение
:deep(input) {
  padding: 8px 0 !important;
}
</style>

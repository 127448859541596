<template>
  <div v-if="list.length">
    <div class="ui-text ui-text_h6 uiKitText--text pa-2">
      {{ title }}
    </div>
    <VList>
      <VListItem
        v-for="(item, index) in list"
        :key="index"
        @click="handleClickItem(item)"
      >
        <VListItemContent class="py-2">
          <div class="ui-text ui-text_body-1 uiKitText--text text-break">
            {{ item.name }}
          </div>
          <div class="ui-text ui-text_body-2 uiKitTextSecondary--text">
            <span
              v-if="isClubActive && item.priceList?.priceClub && item.isWorkplace"
              class="uiKitTextError--text mr-1"
            >{{ getFormattedPrice(item.priceList.priceClub) }}</span>
            <span
              v-if="item.isWorkplace"
              :class="{
                'text-decoration-line-through': isClubActive && item.priceList?.priceClub,
              }"
            >{{ formattedPrice(item) }}</span>
            <span v-else>{{ item.price }}</span>
            <template v-if="item.agesText">
              <span class="uiKitBgGray80--text mx-1">•</span>
              {{ item.agesText }}
            </template>
          </div>
        </VListItemContent>
        <VListItemIcon class="align-self-auto">
          <VIcon>
            ui-icon-arrow-right
          </VIcon>
        </VListItemIcon>
      </VListItem>
    </VList>
  </div>
</template>

<script setup lang="ts">
import type { Service, Workplace } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/types'
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { getFormattedPrice } from 'utils'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'

const props = defineProps<{
  title: string
  list: Array<{
    name?: string
    price: string | null
    agesText: string
    isWorkplace: boolean
  } & Partial<Workplace> & Partial<Service>>
  isLpuAppointmentTypeChosen: boolean
  lpuId: number
}>()

const emit = defineEmits(['click-item'])

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const { redirectValue } = storeToRefs(doctorAppointmentCardStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType } = storeToRefs(doctorPageLpuListStore)

export type Item = typeof props.list[number]

const isClubActive = computed(() => redirectValue.value === 'club' && props.isLpuAppointmentTypeChosen)

function handleClickItem(item: Item) {
  emit('click-item', item)
}

function formattedPrice(item: Item) {
  switch (filterType.value) {
    case 'clinic': {
      const price = item.priceList?.price

      return typeof price === 'number' ? getFormattedPrice(price) : null
    }
    case 'home': {
      const price = item.priceList?.priceHome

      return typeof price === 'number' ? getFormattedPrice(price) : null
    }
    case 'telemed': {
      if (window.FEATURE_FLAGS.pd_medtochka_telemed) {
        const { priceOnline, priceMedtochkaTelemed } = item.priceList || {}

        if (typeof priceOnline === 'number' && typeof priceMedtochkaTelemed === 'number') {
          if (priceOnline !== priceMedtochkaTelemed) {
            return getFormattedPrice(Math.min(priceOnline, priceMedtochkaTelemed), {
              prefix: 'от',
            })
          }
        }

        if (typeof priceOnline === 'number') {
          return getFormattedPrice(priceOnline)
        }

        if (typeof priceMedtochkaTelemed === 'number') {
          return getFormattedPrice(priceMedtochkaTelemed)
        }

        return null
      }

      const price = item.priceList?.priceOnline

      return typeof price === 'number' ? getFormattedPrice(price) : null
    }
  }
}
</script>

import { axiosClient } from 'utils'
import type { ApiScheduleTelemedParamsPost, ApiScheduleTelemedResponsePost } from 'www/api/apiScheduleTelemed.types'

export default {
  post(params: ApiScheduleTelemedParamsPost) {
    return axiosClient.post<ApiScheduleTelemedResponsePost>('/ajax/schedule/slots_bulk/telemed/', params)
  },
  transformSlots(telemedSchedule: ApiScheduleTelemedResponsePost[number]['schedule_data'][number]['schedule']) {
    const slots: {
      [key: string]: {
        duration: number
        free: boolean
        time: string
      }[]
    } = {}

    telemedSchedule.reduce((_, scheduleItem) => {
      slots[scheduleItem.date] = scheduleItem.slots.map(slot => ({
        time: slot.time_start,
        free: slot.free,
        duration: slot.duration * 60,
      }))

      return slots
    }, slots)

    return slots
  },
}

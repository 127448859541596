var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    {
      staticClass: "club-discount-info-bottom-sheet",
      attrs: { "data-club-discount-info-bottom-sheet": "" },
    },
    [
      _vm.isMobile
        ? _c(
            "VBottomSheet",
            {
              attrs: { value: _vm.value, persistent: "" },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            },
            [
              _c(
                "VCard",
                { staticClass: "px-4 py-6" },
                [
                  _c(
                    "div",
                    { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                    [_vm._v("\n        Как работает клубная цена\n      ")]
                  ),
                  _vm._v(" "),
                  _c("ClubDiscountInfo", {
                    staticClass: "mt-6",
                    attrs: {
                      "full-price": _vm.fullPrice,
                      "club-offer-data": _vm.clubOfferData,
                      "club-enabled": "",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "VBtn",
                    {
                      staticClass: "ui-kit-bg-bg-primary mt-6",
                      attrs: { color: "primary", block: "", text: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("input", false)
                        },
                      },
                    },
                    [_vm._v("\n        Хорошо\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "VDialog",
            {
              attrs: { value: _vm.value, "max-width": "476", persistent: "" },
              on: {
                input: function ($event) {
                  return _vm.$emit("input", $event)
                },
              },
            },
            [
              _c(
                "VCard",
                { attrs: { elevation: "0" } },
                [
                  _c("div", { staticClass: "px-2 py-4" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(
                          "VIcon",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.$emit("input", false)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            ui-icon-close-not-a-circle\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_h6 ui-kit-color-text text-no-wrap ml-8",
                          },
                          [
                            _vm._v(
                              "\n            Как работает клубная цена\n          "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("VDivider"),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pa-6" },
                    [
                      _vm.clubOfferData.cardDateEnd
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ui-kit-color-text-secondary ui-text ui-text_body-1 mb-6",
                            },
                            [
                              _vm._v(
                                "\n          Вы в клубе до " +
                                  _vm._s(_vm.clubOfferData.cardDateEnd) +
                                  " 👑\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("ClubDiscountInfo", {
                        attrs: {
                          "full-price": _vm.fullPrice,
                          "club-offer-data": _vm.clubOfferData,
                          "club-enabled": "",
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "VBtn",
                        {
                          staticClass: "ui-kit-bg-bg-primary mt-6",
                          attrs: { color: "primary", block: "", text: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("input", false)
                            },
                          },
                        },
                        [_vm._v("\n          Хорошо\n        ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { AppointmentModules } from 'modules/appointment-modules'
import { apiScheduleTelemed } from 'www/api'

function getCalendarData({ requestData, appointmentObjectTypes, appointmentPlaceTypes }) {
  if (appointmentObjectTypes.isServices) {
    return new AppointmentModules().getAppointmentServicesSlots(requestData)
  }

  if (appointmentObjectTypes.isDoctor) {
    if (
      window.FEATURE_FLAGS.pd_medtochka_telemed
        && (appointmentPlaceTypes.isTelemed || appointmentPlaceTypes.isTelemedMedtochka)
    ) {
      return apiScheduleTelemed.post(requestData)
    }

    return new AppointmentModules().getAppointmentDoctorsSlots(requestData)
  }

  return Promise.reject(new Error('Не указан ни один из возможных appointmentObjectTypes'))
}

export default getCalendarData

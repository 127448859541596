import { apiScheduleTelemed } from 'www/api'
import getInitialDay from 'modules/Schedule/classes/ScheduleData/functions/renderData/helpers/getInitialDay'

/**
 * @description
 * Возвращает объект Map, который используется дальше для рендера календарей для записи к врачу(на странице списка врачей клиники).
 * Функция возвращает список, который состоит из множества объектов рендера, или же только из одного.
 * При спецразмещении может быть несколько дубликатов карточек клиник. Сопоставление переменной 'isSingleRender' точно сообщает - в каком контейнере произошло изменение.
 * Если при клике передан parentCard и не найдено сопоставление - будет ошибка, которая ловится в вызывающем модуле.
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.parentCard - карточка в списке, в которой необходимо заменить расписание
 * @param { Array<Object> } opts.response - ответ на запрос
 * @param { String } opts.dataItem - data атрибут родителя
 *
 * @return { Map } - данные для рендера списка календарей
 * */

const getRenderDoctorsLpuData = opts => {
  const {
    data,
    response,
    dataItem,
    parentCard,
    isTelemed,
  } = opts
  const resultData = new Map()

  data.items.forEach((pageData, scheduleContainer) => {
    const {
      lpuId,
      doctorId,
      coverNode,
      doctorSpecialtyId,
    } = pageData
    const scheduleData = response.find(responseItem => (
      isTelemed
        ? responseItem.schedule_data[0].doctor_id === doctorId
        : responseItem.doctor_id === doctorId
    ))
    const isListRender = !parentCard // рендер списка календарей
    const isSingleRender = parentCard === coverNode // рендер только 1 календаря

    if (!scheduleData) {
      return
    }

    if (!(isListRender || isSingleRender)) {
      return
    }

    const slots = isTelemed
      ? apiScheduleTelemed.transformSlots(scheduleData.schedule_data[0].schedule)
      : scheduleData.slots
    const days = Object.keys(slots)
    const daysWithSlots = days.filter(date => slots[date].length)
    const isEmptySlotList = !daysWithSlots.length

    if (isEmptySlotList) {
      return
    }

    const initDay = getInitialDay({
      daysWithSlots,
      currentSlots: slots,
    })

    resultData.set(scheduleContainer, {
      days,
      slots,
      lpuId,
      initDay,
      dataItem,
      doctorId,
      data: slots,
      options: slots,
      useRedirect: true,
      isAppointmentDoctor: true,
      doctorSpec: doctorSpecialtyId,
    })
  })

  return resultData
}

export default getRenderDoctorsLpuData

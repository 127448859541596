var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isMobile && _setup.doctor
    ? _c(
        "VExpansionPanel",
        [
          _c(
            "VExpansionPanelHeader",
            {
              staticClass: "px-4",
              attrs: { "expand-icon": "ui-icon-arrow-down" },
            },
            [
              _c(
                "div",
                [
                  _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.lpuAddress.lpu.name) +
                        "\n      "
                    ),
                  ]),
                  _vm._v(" "),
                  _setup.filterType !== "telemed"
                    ? [
                        _c("VExpandTransition", [
                          !_vm.isExpanded
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ui-text ui-text_body-2 ui-kit-color-text-secondary",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_setup.fullAddress) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "VExpansionPanelContent",
            [
              _setup.initWorkplaces.length || _setup.initServices.length
                ? _c(_setup.WorkplaceOrServiceSelect, {
                    attrs: {
                      "lpu-id": _vm.lpuAddress.lpu.id,
                      "data-lpu-addr-id": _vm.lpuAddress.id,
                      "init-speciality-translit": _setup.doctor.spec.translit,
                      "init-workplaces": _setup.initWorkplaces,
                      "init-services": _setup.initServices,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _setup.selectedItem.priceList
                ? _c(_setup.DoctorPriceBlockItemList, {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _setup.filterType !== "home",
                        expression: "filterType !== 'home'",
                      },
                    ],
                    attrs: {
                      "page-type": _setup.filterType,
                      price:
                        /* @ts-ignore */ _setup.selectedItem.priceList[
                          _setup.translatedChosenFilter
                        ],
                      "club-price":
                        /* @ts-ignore */ _setup.selectedItem.priceList
                          .priceClub,
                      "telemed-medtochka-price":
                        /* @ts-ignore */ _setup.selectedItem.priceList
                          .priceMedtochkaTelemed,
                      "speciality-selected": "",
                    },
                    on: {
                      "update:redirect-value":
                        _setup.handlePriceBlockUpdateRedirectValue,
                      "update:telemed-medtochka-state":
                        _setup.handlePriceBlockUpdateTelemedMedtochkaState,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _setup.initWorkplaces.length || _setup.initServices.length
                ? _c(_setup.WorkplaceOrServiceCalendar, {
                    staticClass: "my-3",
                    attrs: { "lpu-address": _vm.lpuAddress },
                  })
                : _vm._e(),
              _vm._v(" "),
              _setup.filterType !== "telemed"
                ? [
                    _c(
                      "div",
                      {
                        directives: [{ name: "ripple", rawName: "v-ripple" }],
                        staticClass:
                          "d-flex align-center ui-text ui-text_body-1 py-2 cursor-pointer",
                        on: { click: _setup.handlePopupMapButtonClick },
                      },
                      [
                        _c(
                          "VIcon",
                          {
                            staticClass: "mr-4",
                            attrs: { size: "20", color: "uiKitIconSecondary" },
                          },
                          [_vm._v("\n          ui-icon-map-pin\n        ")]
                        ),
                        _vm._v(
                          "\n        " +
                            _vm._s(_setup.fullAddress) +
                            "\n        "
                        ),
                        _c("VSpacer"),
                        _vm._v(" "),
                        _c("VIcon", [
                          _vm._v("\n          ui-icon-arrow-right\n        "),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm.lpuAddress.lpu.lpumetro_set.all.length
                      ? _c(_setup.DoctorPageLpuListMetroList, {
                          staticClass: "mt-2",
                          attrs: { "lpu-address": _vm.lpuAddress },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(_setup.DoctorPageLpuListPhone, {
                attrs: { "lpu-address": _vm.lpuAddress },
              }),
              _vm._v(" "),
              _c(
                "a",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass:
                    "d-flex align-center text-decoration-none ui-text ui-text_body-1 py-2 mt-2",
                  attrs: { href: _setup.lpuUrl },
                },
                [
                  _c(
                    "VIcon",
                    {
                      staticClass: "mr-4",
                      attrs: { size: "20", color: "uiKitIconSecondary" },
                    },
                    [_vm._v("\n        ui-icon-home-ProDoctorov\n      ")]
                  ),
                  _vm._v("\n      Страница клиники\n      "),
                  _c("VSpacer"),
                  _vm._v(" "),
                  _c("VIcon", [
                    _vm._v("\n        ui-icon-arrow-right\n      "),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _setup.isBannerMtLinkVisible
                ? _c(_setup.BannerMtLink, {
                    staticClass: "mt-4",
                    attrs: {
                      text: "К врачу можно приходить с\u00A0электронной медкартой",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      )
    : _setup.doctor
    ? _c(
        "div",
        { staticClass: "doctor-page-list-lpu pa-6" },
        [
          _setup.isBannerMtLinkVisible
            ? _c(_setup.BannerMtLink, {
                staticClass: "mb-6",
                attrs: {
                  text: "К врачу можно приходить с\u00A0электронной медкартой",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex" },
            [
              _c(
                "VSheet",
                { staticClass: "d-flex flex-column", attrs: { width: "336" } },
                [
                  _c(
                    "a",
                    {
                      staticClass:
                        "ui-text ui-text_subtitle-1 ui-kit-color-primary text-decoration-none",
                      attrs: { href: _setup.lpuUrl },
                    },
                    [_vm._v(_vm._s(_vm.lpuAddress.lpu.name))]
                  ),
                  _vm._v(" "),
                  _setup.isUserStaff
                    ? _c("div", { staticClass: "mt-4" }, [
                        _c(
                          "a",
                          {
                            staticClass: "adminka",
                            attrs: {
                              href: `${_setup.urls?.problemsWithSlots}?lpu_id=${_vm.lpuAddress.lpu.id}&doctor_id=${_vm.lpuAddress.doctor_id}`,
                              target: "_blank",
                              rel: "noopener noreferrer",
                            },
                          },
                          [_vm._v("Проблемы с расписанием")]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _setup.initWorkplaces.length || _setup.initServices.length
                    ? _c(_setup.WorkplaceOrServiceSelect, {
                        staticClass: "mt-6",
                        attrs: {
                          label: "Специальность или услуга",
                          "lpu-id": _vm.lpuAddress.lpu.id,
                          "data-lpu-addr-id": _vm.lpuAddress.id,
                          "init-speciality-translit":
                            _setup.doctor.spec.translit,
                          "init-workplaces": _setup.initWorkplaces,
                          "init-services": _setup.initServices,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _setup.selectedItem.priceList
                    ? _c(_setup.DoctorPriceBlockItemList, {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _setup.filterType !== "home",
                            expression: "filterType !== 'home'",
                          },
                        ],
                        attrs: {
                          "page-type": _setup.filterType,
                          price:
                            /* @ts-ignore */ _setup.selectedItem.priceList[
                              _setup.translatedChosenFilter
                            ],
                          "club-price":
                            /* @ts-ignore */ _setup.selectedItem.priceList
                              .priceClub,
                          "telemed-medtochka-price":
                            /* @ts-ignore */ _setup.selectedItem.priceList
                              .priceMedtochkaTelemed,
                          "speciality-selected": "",
                        },
                        on: {
                          "update:redirect-value":
                            _setup.handlePriceBlockUpdateRedirectValue,
                          "update:telemed-medtochka-state":
                            _setup.handlePriceBlockUpdateTelemedMedtochkaState,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _setup.filterType !== "telemed"
                    ? [
                        _c(
                          "div",
                          {
                            directives: [
                              { name: "ripple", rawName: "v-ripple" },
                            ],
                            staticClass:
                              "d-flex align-center ui-text ui-text_body-1 ui-kit-color-primary py-2 cursor-pointer mt-4",
                            on: { click: _setup.handlePopupMapButtonClick },
                          },
                          [
                            _c(
                              "VIcon",
                              {
                                staticClass: "mr-4",
                                attrs: {
                                  size: "20",
                                  color: "uiKitIconSecondary",
                                },
                              },
                              [
                                _vm._v(
                                  "\n            ui-icon-map-pin\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _setup.isUserStaff
                              ? [
                                  _c("div", [
                                    _c(
                                      "span",
                                      { staticClass: "ui-kit-color-text" },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              _vm.lpuAddress.lpu.town
                                                .translit !==
                                                _setup.townTranslit
                                                ? `${_setup.props.lpuAddress.lpu.town.name},`
                                                : ""
                                            ) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.lpuAddress.address) +
                                        "\n            "
                                    ),
                                  ]),
                                ]
                              : [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_setup.fullAddress) +
                                      "\n          "
                                  ),
                                ],
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _vm.lpuAddress.lpu.lpumetro_set.all.length
                          ? _c(_setup.DoctorPageLpuListMetroList, {
                              staticClass: "mt-2",
                              attrs: { "lpu-address": _vm.lpuAddress },
                            })
                          : _vm._e(),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _c(_setup.DoctorPageLpuListPhone, {
                    attrs: { "lpu-address": _vm.lpuAddress },
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c("VSpacer"),
              _vm._v(" "),
              _c(
                "VSheet",
                { attrs: { width: "389" } },
                [
                  _setup.initWorkplaces.length || _setup.initServices.length
                    ? _c(_setup.WorkplaceOrServiceCalendar, {
                        attrs: { "lpu-address": _vm.lpuAddress },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <VExpansionPanels
    v-if="isMobile"
    v-model="expandedPanels"
    accordion
    multiple
    tile
  >
    <DoctorPageLpuListLpu
      v-for="(lpuAddress, lpuAddressIndex) in lpuAddressListFiltered"
      :key="`${lpuAddress.id}-${lpuAddressIndex}-${filterType}`"
      :is-expanded="expandedPanels.includes(lpuAddressIndex)"
      :lpu-address="lpuAddress"
    />
  </VExpansionPanels>
  <VCard
    v-else
    class="rounded-t-0"
    elevation="1"
  >
    <DoctorPageLpuListLpu
      v-for="lpuAddress in lpuAddressListFiltered"
      :key="lpuAddress.id"
      :lpu-address="lpuAddress"
    />
  </VCard>
</template>

<script setup lang="ts">
import { nextTick, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
// eslint-disable-next-line import/extensions
import DoctorPageLpuListLpu from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListLpu.vue'

const externalDataStore = useExternalDataStore()
const { isMobile } = storeToRefs(externalDataStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType, expandedPanels, lpuAddressListFiltered } = storeToRefs(doctorPageLpuListStore)
const { updateSlots } = doctorPageLpuListStore

watch(filterType, async () => {
  /**
   * nextTick нужен, чтобы установились значения selectedItem, которые используются в useDoctorAppointmentCardStore
   */
  await nextTick()
  await updateSlots()
}, { immediate: true })
</script>

import {
  isEmptyObject,
  getTypeObject,
} from 'utils'

import {
  PAGE_TYPE_DATA,
  VALIDATION_DATA,
} from 'modules/Schedule/constants'

import {
  getPageDataInterface,
  getRequestDataInterface,
} from 'modules/Schedule/functions'

import {
  getModuleDataInterface,
  getNodesItemInterface,
  getNodesStorageInterface,
  getValidationResultInterface,
} from 'modules/Schedule/models'

/**
 * @description
 * Модуль производит валидацию данных.
 * Логика работы заключается в том, что ему в конструктор передается хранилище данных - 'getModuleDataInterface'.
 * И каждое поле хранилища по отдельности валидируется с помощью геттеров.
 * */
class ScheduleValidator {
  #data = getModuleDataInterface()

  #pageType = '' // одно из значений 'PAGE_TYPE_DATA'

  constructor({ data, pageType }) {
    this.#data = data
    this.#pageType = pageType
  }

  update({ data }) { this.#data = data }

  /**
     * @public
     * @description
     * Производит валидацию собранных со страницы данных - 'getModuleDataInterface().items' & 'getModuleDataInterface().storage'.
     * Выполняются следующие проверки:
     * 1. 'items' должен быть 'Map' и иметь длину.
     * 2. 'storage' должен быть 'Object' и не должен быть пустым.
     * 3. Поля интерфейса для 'items' и 'storage' должны совпадать с полями, которые собраны со страницы.
     * */
  get testPageData() {
    const { itemsInterface, storageInterface } = getPageDataInterface(this.#pageType)

    const keysItemInterface = Object.keys({ ...itemsInterface, ...getNodesItemInterface() })
    const keysStorageInterface = Object.keys({ ...storageInterface, ...getNodesStorageInterface() })

    const isMapItems = getTypeObject(this.#data.items) === 'map' && this.#data.items.size
    const isObjectStorage = getTypeObject(this.#data.storage) === 'object' && !isEmptyObject(this.#data.storage)

    const hasAllFieldsDataItem = [...this.#data.items].map(([, options]) => (
      keysItemInterface.every(item => Object.keys(options).includes(item))
    )).every(i => i)

    const hasAllFieldsDataStorage = Object.keys(this.#data.storage).map(key => (
      keysStorageInterface.includes(key)
    )).every(i => i)

    if (!isMapItems) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.pageData.title,
        subtitle: VALIDATION_DATA.pageData.detailData.isMapMessage,
        result: false,
      })
    }

    if (!isObjectStorage) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.pageData.title,
        subtitle: VALIDATION_DATA.pageData.detailData.isObjectMessage,
        result: false,
      })
    }

    if (!hasAllFieldsDataItem) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.pageData.title,
        subtitle: VALIDATION_DATA.pageData.detailData.isItemsInterfaceMessage,
        result: false,
      })
    }

    if (!hasAllFieldsDataStorage) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.pageData.title,
        subtitle: VALIDATION_DATA.pageData.detailData.isStorageInterfaceMessage,
        result: false,
      })
    }

    return getValidationResultInterface({
      result: isMapItems
            && isObjectStorage
            && hasAllFieldsDataItem
            && hasAllFieldsDataStorage,
    })
  }

  /**
     * @public
     * @description
     * Производит валидацию данных, которые будут использованы для рендера расписания.
     * 1. 'render' должен быть 'Map' и иметь длину.
     * */
  get testRenderData() {
    const baseTest = getTypeObject(this.#data.render) === 'map' && this.#data.render.size

    if (!baseTest) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.renderData.title,
        subtitle: VALIDATION_DATA.renderData.detailData.isMapMessage,
        result: false,
      })
    }

    return getValidationResultInterface({ result: baseTest })
  }

  /**
     * @public
     * @description
     * Производит валидацию данных, которые будут использованы для рендера расписания.
     * 1. 'request' должен быть 'Object' и не должен быть пустым.
     * 2. Если необходимо сделать запрос со специальностями врачей(lpu):
     * 2.1. Поле 'lpus_specialities' не должно быть пустым
     * 2.2. Поля интерфейса для 'request' должны совпадать с полями, которые собраны со страницы.
     * 3. Если необходимо сделать запрос для врачей(doctors, doctorsLpu):
     * 3.1. Поля интерфейса для 'request' должны совпадать с полями, которые собраны со страницы.
     * 3.2. Если запрос производится для онлайн-города, поле 'lpu_timedelta' не должно быть пустым:
     * 3.3. Если запрос производится для обычного города, поле 'doctors_lpus' не должно быть пустым:
     * 4. Если необходимо сделать запрос для услуг(services):
     * 4.1. Поле 'services' не должно быть пустым
     * 4.2. Поля интерфейса для 'request' должны совпадать с полями, которые собраны со страницы.
     * */
  get testRequestData() {
    const baseTest = getTypeObject(this.#data.request) === 'object' && !isEmptyObject(this.#data.request)

    if (!baseTest) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.isObjectMessage,
        result: false,
      })
    }

    switch (this.#pageType) {
      case PAGE_TYPE_DATA.lpu:
        return this._testLpuRequestData()
      case PAGE_TYPE_DATA.doctors:
      case PAGE_TYPE_DATA.doctorsLpu:
        return this._testDoctorsRequestData()
      case PAGE_TYPE_DATA.services:
        return this._testServicesRequestData()
    }

    return getValidationResultInterface({ result: false })
  }

  /**
     * @private
     * @description
     * Используется во время валидации данных геттером 'testRequestData' для 'lpu'
     * */
  _testLpuRequestData() {
    const isNotEmptyList = !!this.#data.request.lpus_specialities.length
    const baseInterface = getRequestDataInterface({ pageType: this.#pageType })
    const hasInterfaceMatching = this._hasInterfaceRequestMatching(baseInterface)

    if (!isNotEmptyList) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.isEmptyLpusSpecialities,
        result: false,
      })
    }

    if (!hasInterfaceMatching) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.interfaceMessage,
        result: false,
      })
    }

    return getValidationResultInterface({ result: true })
  }

  /**
     * @private
     * @description
     * Используется во время валидации данных геттером 'testRequestData' для 'doctors, doctorsLpu'
     * */
  _testDoctorsRequestData() {
    const isDoctorOnline = window.FEATURE_FLAGS.pd_medtochka_telemed
      ? this.#data.request.user_timezone
      : this.#data.request.user_timedelta && this.#data.request.lpu_timedelta
    const baseInterface = getRequestDataInterface({ pageType: this.#pageType, isDoctorOnline })
    const hasInterfaceMatching = this._hasInterfaceRequestMatching(baseInterface)

    if (isDoctorOnline) {
      const isNotEmptyList = window.FEATURE_FLAGS.pd_medtochka_telemed
        ? !!this.#data.request.lpu_params.length
        : !!this.#data.request.lpu_timedelta.length

      if (!isNotEmptyList) {
        return getValidationResultInterface({
          title: VALIDATION_DATA.requestData.title,
          subtitle: VALIDATION_DATA.requestData.detailData.isEmptyDoctorsLpuTimedelta,
          result: false,
        })
      }
    } else {
      const isNotEmptyList = !!this.#data.request.doctors_lpus.length

      if (!isNotEmptyList) {
        return getValidationResultInterface({
          title: VALIDATION_DATA.requestData.title,
          subtitle: VALIDATION_DATA.requestData.detailData.isEmptyDoctorsLpus,
          result: false,
        })
      }
    }

    if (!hasInterfaceMatching) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.interfaceMessage,
        result: false,
      })
    }

    return getValidationResultInterface({ result: true })
  }

  /**
     * @private
     * @description
     * Используется во время валидации данных геттером 'testRequestData' для 'services'
     * */
  _testServicesRequestData() {
    const isNotEmptyList = !!this.#data.request.services.length
    const baseInterface = getRequestDataInterface({ pageType: this.#pageType })
    const hasInterfaceMatching = this._hasInterfaceRequestMatching(baseInterface)

    if (!isNotEmptyList) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.isEmptyServices,
        result: false,
      })
    }

    if (!hasInterfaceMatching) {
      return getValidationResultInterface({
        title: VALIDATION_DATA.requestData.title,
        subtitle: VALIDATION_DATA.requestData.detailData.interfaceMessage,
        result: false,
      })
    }

    return getValidationResultInterface({ result: true })
  }

  /**
     * @private
     * @description
     * Используется во время валидации данных геттером 'testRequestData' для сопоставления интерфейсов
     * */
  _hasInterfaceRequestMatching(baseInterface) {
    return Object.keys(baseInterface)
      .map(key => (Object.keys(this.#data.request).includes(key)))
      .every(i => i)
  }
}

export default ScheduleValidator

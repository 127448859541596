import { format } from 'date-fns'
import { getTimezoneOffsetInHours } from 'utils'
import {
  getDoctorRequestInterface,
  getDoctorOnlineRequestInterface,
} from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для врачей
 *
 * @param { Object } opts
 * @param { Object } opts.data - данные 'ScheduleData'
 * @param { Array<Object> } opts[doctorsLpusPart] - список с [{ doctor_fio, doctor_id, lpu_id }], аналогичный data.storage.doctorsLpus, просто собран вручную
 * Используется на странице списка врачей клиники для запросов расписания по частям. Если это поле передано - у него приоритет перед data.storage.doctorsLpus
 *
 * @return { Object } - объект в формате 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface'
 * */

const getDoctorsRequestData = ({ data, doctorsLpusPart = [] }) => {
  const {
    dateStart,
    timedelta,
    doctorsLpus,
    isDoctorListOnline,
    isFilterByPlaceOnline,
  } = data.storage

  const isTelemed = isDoctorListOnline || isFilterByPlaceOnline
  const isNewTelemed = isTelemed && window.FEATURE_FLAGS.pd_medtochka_telemed
  const baseData = isTelemed ? getDoctorOnlineRequestInterface() : getDoctorRequestInterface()
  const doctorsHasSlots = doctorsLpusPart.length
    ? doctorsLpusPart
    : doctorsLpus.filter(({ has_slots: hasSlots }) => hasSlots)

  if (!isNewTelemed) {
    baseData.doctors_lpus = doctorsHasSlots
  }

  if (isTelemed) {
    if (isNewTelemed) {
      baseData.user_start_date = format(new Date(), 'yyyy-MM-dd')
      baseData.user_timezone = getTimezoneOffsetInHours()
      baseData.lpu_params = doctorsHasSlots.map(({
        lpu_id: lpuId,
        doctor_id: doctorId,
        telemed_price: telemedPrice,
        telemed_medtochka_price: telemedMedtochkaPrice,
      }) => {
        /** @type {import('www/api/apiScheduleTelemed.types').ScheduleType} */
        let scheduleType = 'all'

        if (telemedPrice && telemedMedtochkaPrice && telemedPrice !== telemedMedtochkaPrice) {
          scheduleType = telemedMedtochkaPrice < telemedPrice ? 'mt' : 'lpu'
        }

        return {
          lpu_id: lpuId,
          schedule_params: [{
            doctor_id: doctorId,
            type_schedule: scheduleType,
          }],
        }
      })
    } else {
      baseData.dt_start = format(new Date() || 1, 'yyyy-MM-dd') // оператор '||' нужен, чтоб IDE не ругался
      baseData.user_timedelta = getTimezoneOffsetInHours()
      baseData.lpu_timedelta = doctorsHasSlots
        .map(({ lpu_id, lpu_timedelta }) => [lpu_id, lpu_timedelta]) // eslint-disable-line camelcase
        .filter(i => i)
    }
  } else {
    baseData.dt_start = dateStart
    baseData.town_timedelta = timedelta
  }

  return baseData
}

export default getDoctorsRequestData

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-qa": "doctor_service_prices" } },
    [
      !_vm.isMounted
        ? _vm._t("default")
        : [
            _c(
              "div",
              {
                staticClass: "d-flex justify-space-between mb-3",
                attrs: { "data-qa": "doctor_service_prices_header" },
              },
              [
                _vm.headerText
                  ? _c("h2", {
                      staticClass: "ui-text ui-text_h5 mr-4",
                      attrs: { "data-qa": "doctor_service_prices_header_text" },
                      domProps: { textContent: _vm._s(_vm.headerText) },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.lpuList.length > 1
                  ? _c("LpuSelect", {
                      attrs: { "lpu-list": _vm.lpuList },
                      model: {
                        value: _vm.selectedLpuId,
                        callback: function ($$v) {
                          _vm.selectedLpuId = $$v
                        },
                        expression: "selectedLpuId",
                      },
                    })
                  : _vm.firstLpu
                  ? _c(
                      "div",
                      {
                        staticClass: "doctor-service-prices-lpu",
                        class: {
                          "doctor-service-prices-lpu_desktop": !_vm.isMobile,
                        },
                        attrs: { "data-qa": "doctor_service_prices_lpu" },
                      },
                      [
                        _c("div", {
                          staticClass:
                            "ui-text ui-text_subtitle-1 uiKitText--text",
                          attrs: {
                            "data-qa": "doctor_service_prices_lpu_name",
                          },
                          domProps: { textContent: _vm._s(_vm.firstLpu.name) },
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass:
                            "ui-text ui-text_body-2 uiKitTextSecondary--text",
                          attrs: {
                            "data-qa": "doctor_service_prices_lpu_address",
                          },
                          domProps: {
                            textContent: _vm._s(_vm.firstLpu.address),
                          },
                        }),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("SearchCombobox", {
              staticClass: "mb-2",
              attrs: {
                placeholder: "Найти услугу",
                "data-qa": "doctor_services_search",
                "initial-data": _vm.serviceCategoryList,
              },
              on: {
                "search:default": _vm.handleSearchDefault,
                "search:text": _vm.handleSearchText,
                "search:class": _vm.handleSearchCategory,
              },
            }),
            _vm._v(" "),
            _vm.isEnabledFFOnlineSwitch
              ? _c(
                  "div",
                  {
                    staticClass: "mb-2",
                    class: {
                      "d-flex justify-content-between align-center":
                        !_vm.isMobile && _vm.isShowOnlineSwitch,
                    },
                  },
                  [
                    _vm.isShowOnlineSwitch
                      ? _c("OnlineSwitch", {
                          staticClass: "my-2",
                          attrs: {
                            "is-enabled-online-app": _vm.isEnabledOnlineApp,
                          },
                          on: {
                            "online-switch:change": _vm.handleSwitchChange,
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("UpdateInfo", {
                      staticClass: "mb-8",
                      attrs: {
                        status:
                          _vm.selectedFormattedServicePricesData.updateInfo
                            .status,
                        "date-string":
                          _vm.selectedFormattedServicePricesData.updateInfo
                            .dateString,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.isVisibleServiceList
              ? [
                  !_vm.isEnabledFFOnlineSwitch
                    ? _c("UpdateInfo", {
                        staticClass: "mb-8",
                        attrs: {
                          status:
                            _vm.selectedFormattedServicePricesData.updateInfo
                              .status,
                          "date-string":
                            _vm.selectedFormattedServicePricesData.updateInfo
                              .dateString,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("PriceList", {
                    attrs: { "category-list": _vm.searchData.categoryList },
                    on: {
                      "click:show-calendar": _vm.handleShowCalendar,
                      "click:hide-calendar": _vm.handleHideCalendar,
                    },
                  }),
                ]
              : _c("div", { staticClass: "text-center py-17" }, [
                  _c("img", {
                    staticClass: "d-block mb-2 mx-auto",
                    attrs: {
                      "data-qa": "doctor_services_stub",
                      src: "/static/_v1/pd/pictures/ui-kit/search.svg",
                      width: "150",
                      height: "150",
                      alt: "/",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "ui-text ui-kit-color-text ui-text_body-1" },
                    [_vm._v("\n        Услуга не найдена\n      ")]
                  ),
                ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }